<template>
  <div class="base-text-block">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseTextBlock',
}
</script>

<style lang="scss" scoped>
.base-text-block {
  display: block;
  position: relative;
  padding: 2.5rem 2rem;
  background: rgba(0,0,0,0.025);
  color: #324a66;
  border-radius: 5px;
}
</style>