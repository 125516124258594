<template>
  <form>
    <div class="form-row">
      <label class="form-label">Email address</label>
      <input type="email" class="form-control" placeholder="Email address"
             aria-describedby="emailHelp" v-model="user.email">
    </div>
    <div class="form-row">
      <label class="form-label">Password</label>
      <div v-if="!passwordVisible" class="input-group mb-3">
        <input type="password" class="form-control" placeholder="Password" v-model="user.password">
        <div class="input-group-append">
          <span class="input-group-text" @click="togglePasswordVisible"><b-icon
              icon="eye-slash-fill"></b-icon></span>
        </div>
      </div>
      <div v-if="passwordVisible" class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Password" v-model="user.password">
        <div class="input-group-append">
          <span class="input-group-text" @click="togglePasswordVisible"><b-icon icon="eye-fill"></b-icon></span>
        </div>
      </div>
    </div>
    <div class="form-row form-actions">
      <div class="mt-3 d-flex flex-column">
        <button
            @click.prevent="signWithEmailAndPassword"
            class="btn btn-primary"
        >
          Sign in
        </button>
        <div class="d-flex justify-content-between">
          <button
              class="btn btn-link"
              @click.prevent="setActiveView('register')"
          >
            Create account
          </button>
          <button
              class="btn btn-link"
              @click.prevent="setActiveView('reset-password')"
          >
            Reset password
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AccountsService from "@/services/accounts/AccountsService";

export default {
  name: "SignInForm",
  data() {
    return {
      passwordVisible: false,
      user: {}
    }
  },
  methods: {
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
    setActiveView(view) {
      this.$store.commit("setActiveMenuView", view)
    },
    clearFormData() {
      this.user = {}
    },
    async signWithEmailAndPassword() {
      let user = await AccountsService.signInWithEmailPassword(this.user.email, this.user.password)
      if (user.status) {
        this.$toast.error(user.message);
        this.clearFormData();
        return
      }
      if (user.verified) {
        this.$store.commit("setUserDetails", user)
        this.$store.commit("setLoggedInStatus", true)
        this.$toast.success(`Welcome back, ${user.firstname}. You are signed in`);
        this.$store.commit("setActiveMenuView", 'my-account')
        this.$emit("signInSuccess");
        await this.$router.push({name: 'Home'});
      } else {
        this.$toast.info("You have not verified your account. Check your email for verification link");
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-append {
  .input-group-text {
    height: 100%;
    font-size: .75em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
