<template>
  <form>
    <div class="form-row">
      <label class="form-label">First name</label>
      <input type="text" class="form-control" placeholder="First name" v-model="user.firstname">
    </div>
    <div class="form-row">
      <label class="form-label">Last name</label>
      <input type="text" class="form-control" placeholder="Last name" v-model="user.lastname">
    </div>
    <div class="form-row">
      <label class="form-label">Email address</label>
      <input type="email" class="form-control" placeholder="Email address"
             aria-describedby="emailHelp" v-model="user.email">
    </div>
    <div class="form-row">
      <label class="form-label">Password</label>
      <div>
        <!--        TODO: Refactor this to component"-->
        <div v-if="!passwordVisible" class="input-group mb-3">
          <input type="password" class="form-control" placeholder="Password" v-model="user.password">
          <div class="input-group-append">
            <span class="input-group-text" @click="togglePasswordVisible"><b-icon icon="eye-slash-fill"></b-icon></span>
          </div>
        </div>
        <div v-if="passwordVisible" class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Password" v-model="user.password">
          <div class="input-group-append ">
            <span class="input-group-text" @click="togglePasswordVisible"><b-icon icon="eye-fill"></b-icon></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <label class="form-label">Confirm Password</label>
      <div>
        <div v-if="!passwordVisible" class="input-group mb-3">
          <input type="password" class="form-control" placeholder="Confirm Password" v-model="user.confirmPassword">
          <div class="input-group-append">
            <span class="input-group-text" @click="togglePasswordVisible"><b-icon icon="eye-slash-fill"></b-icon></span>
          </div>
        </div>
        <div v-if="passwordVisible" class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Confirm Password" v-model="user.confirmPassword">
          <div class="input-group-append ">
            <span class="input-group-text" @click="togglePasswordVisible"><b-icon icon="eye-fill"></b-icon></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="terms" required v-model="user.terms">
        <label class="form-check-label" for="terms">
          Yes, I understand and agree to the <a href="/legal/terms-of-use.pdf" target="_blank">Terms of Use</a>
          and
          <a href="/legal/privacy-policy.pdf" target="_blank">Privacy Policy</a>
        </label>
      </div>
    </div>
    <div class="form-row form-actions">
      <div class="mt-3 d-flex flex-column">
        <button class="btn btn-primary" @click.prevent="createAccountEmailAndPassword">Continue
        </button>
        <button class="btn btn-link" @click.prevent="setActiveView('signin')">Sign in instead
        </button>
      </div>
    </div>
  </form>

</template>

<script>
import AccountsService from "@/services/accounts/AccountsService";
import EmailService from "@/services/email/EmailService";

export default {
  name: "SignUpForm",
  data() {
    return {
      passwordVisible: false,
      user: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        terms: false,
        verified: true,
        role: "user"
      }
    }
  },
  methods: {
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
    setActiveView(view) {
      this.$store.commit("setActiveMenuView", view)
    },
    confirmPasswordViability() {
      let passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
      if (this.user.password.match(passwordRegex) == null || this.user.password !== this.user.confirmPassword) {
        this.$toast.error("The password must be more than 8 characters, contain at least 1 number, 1 symbol, 1 upper case and same as the confirm password")
        return false
      }
      return true
    },
    confirmAcceptedTermsAndConditions() {
      if (this.user.terms === false) {
        this.$toast.error("You must agree before submitting!");
        return false;
      }
      return true;
    },
    clearFormData() {
      this.user = {}
    },
    sendEmailVerification(id, email) {
      let verificationLink = `${window.location.origin}/accounts/verify-account?token=${id}`
      let verificationMessage = `<p>Please click the link below to verify your account</p>
                                <p><a href="${verificationLink}">${verificationLink}</a></p>`
      EmailService.sendMail(this.user.email, verificationMessage)
    },
    async createAccountEmailAndPassword() {
      if (this.confirmPasswordViability() && this.confirmAcceptedTermsAndConditions()) {
        let accountCreated = await AccountsService.createUserAccount(this.user)
        if (accountCreated.status === 'success') {
          this.$toast.success(accountCreated.message);
          this.sendEmailVerification(accountCreated.id, this.user.email)
          this.clearFormData();
          await this.$router.push({name: "SignIn"})
        } else {
          this.$toast.error(accountCreated.message);
        }

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-padding {
  padding: 0.375rem 0.75rem;
}

.input-group-append {
  .input-group-text {
    height: 100%;
    font-size: .75em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
