import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {getImagesFromLocalStorage, saveImagesToLocalStorage} from "@/assets/common";
import ImageService from "@/services/image/ImageService";
import Collections from "@/services/collections/collections";

Vue.use(Vuex)

let store;
export default store = new Vuex.Store({
    state: {
        images: [],
        cartItemUniqueList: [],
        currentProductImageIndex: 0,
        currentBrowseImageIndex: 0,
        isLoggedIn: false,
        pathName: "",
        userPermissions: {},
        user: {},
        activeMenuView: '',
        isMenuOpen: false,
        isCartOpen: false,

    },
    mutations: {
        setUserPermissions(state, permissions) {
            this.state.userPemisisons = permissions
        },
        addImages(state, images) {
            this.state.images.push(...images)
        },
        addCartItemUniqueList(state, item) {
            this.state.cartItemUniqueList.push(item)
        },
        setCurrentProductImageIndex(state, currentProductImageIndex) {
            this.state.currentProductImageIndex = currentProductImageIndex
        },
        setCurrentBrowseImageIndex(state, currentBrowseImageIndex) {
            this.state.currentBrowseImageIndex = currentBrowseImageIndex
        },
        setLoggedInStatus(state, loggedIn) {
            this.state.isLoggedIn = loggedIn
        },
        setPathName(state, path) {
            this.state.pathName = path
        },
        setUserDetails(state, user) {
            this.state.user = user
        },
        setActiveMenuView(state, viewName) {
            this.state.activeMenuView = viewName
        },
        openMenu(state) {
            state.isMenuOpen = true
        },
        openCart(state) {
            state.isCartOpen = true
        },
        closeMenu(state) {
            state.isMenuOpen = false
            state.isCartOpen = false
        },
    },
    actions: {
        "images/getImages": async function ({commit}) {
            let items = await getImagesFromLocalStorage();
            if (items === null || items.length === 0) {
                items = await ImageService.getImages();
                saveImagesToLocalStorage(items);
            }
            return items;
        },
        "images/getTopImages": async function ({commit}, payload) {
            return await ImageService.getTopImages(payload.limit);
        },
        "collections/getCollections": async function ({commit}, payload) {
            return await Collections.getCollections(payload);
        },
        "collections/addCollection": async function ({commit}, collection) {
            return await Collections.addCollection(collection);
        },
        "collections/updateCollection": async function ({commit}, collection) {
            return await Collections.updateCollection(collection.id, collection);
        },
        "collections/deleteCollection": async function ({commit}, collectionId) {
            return await Collections.deleteCollection(collectionId);
        }

    },
    modules: {},
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })]
})
