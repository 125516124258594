import OrderDao from "@/services/order/OrderDao";

class OrderService {
    constructor() {
        this.recordUrl = `orders`
    }

    async saveOrder(order) {
        return await OrderDao.addRecord(order, this.recordUrl)
    }

    async getOrderDetails(orderId) {
        return await OrderDao.getRecord(orderId, this.recordUrl)
    }

    async getUserOrders(userId) {
        return await OrderDao.getRecord(userId, this.recordUrl)
    }

}

export default new OrderService();
