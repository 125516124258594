<template>
  <slip theme="download">
    <div class="col-5 col-sm-4 col-md-3">
      <img
          :src="item.image.url"
          v-watermark
          alt="thumbnail image"
          class="img-fluid rounded-3"
      >
    </div>
    <div class="col-6 col-sm">
      <p><span>License:</span> {{ item.license.name }}</p>
      <p><span>Size:</span> {{ item.license.description }} px</p>
    </div>
    <div class="col">
      <button
          class="btn btn-sm btn-success"
          @click="downloadImage(item.image.downloadUrl, item.image.id)"
      >
        Download
        <span><b-icon icon="download" /></span>
      </button>
    </div>
  </slip>
</template>
<script>

import Slip from "@/components/Slip";
export default {
  name: "DownloadItem",
  components: {Slip},
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    downloadImage(url, filename) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    }
  }
}

</script>
<style lang="scss" scoped>
p {
  font-size: .875em;
  margin: 0 0 .5rem;

  span {
    display: block;
    font-size: .875em;
    color: #777777;

    @media (min-width: 576px) {
      display: inline-block;
    }
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.btn {
  float: right;
}
</style>
