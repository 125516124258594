<template>
  <footer class="footer">
    <div class="container-xl">
      <div class="row">
        <div class="info-col col-lg-4">
          <div class="info-wrapper">
            <logo text-color="light" class="logo" />
            <p>
              Thome Images provides authentic and fully licensed photographs and prints of
              tribalism, religion, and rare wonders of the natural world.

              These vintage photos give a glimpse into the customs of world culture.
            </p>
          </div>
        </div>
        <div class="links-col col-lg-4">
          <h3>Links</h3>
          <ul>
            <b-nav-item to="/" exact exact-active-class="active">Home</b-nav-item>
            <b-nav-item to="/images" exact exact-active-class="active">Images</b-nav-item>
            <li v-for="(document, index) in documents" :key="index">
              <a :href="document.link" target="_blank">{{ document.name }}</a>
            </li>
            <li>
              <a :href="emailUrl">Contact Us</a><span class="side-text">via email</span>
            </li>
          </ul>
        </div>
        <div class="social-col col-lg-4 text-lg-end">
          <h3>Spread the word!</h3>
          <div class="grid-wrapper">
            <facebook :url="url" :title="socialTitle" :scale="socialScale"></facebook>
            <twitter :url="url" :title="socialTitle" :scale="socialScale"></twitter>
            <linkedin :url="url" :title="socialTitle" :scale="socialScale"></linkedin>
            <telegram :url="url" :title="socialTitle" :scale="socialScale"></telegram>
            <whats-app :url="url" :title="socialTitle" :scale="socialScale"></whats-app>
            <pinterest :url="url" :title="socialTitle" :scale="socialScale"></pinterest>
            <reddit :url="url" :title="socialTitle" :scale="socialScale"></reddit>
            <google :url="url" :title="socialTitle" :scale="socialScale"></google>
            <email :url="url" :subject="socialTitle" :scale="socialScale"></email>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      &copy; Copyright {{ year }}
      <a :href="company.website" target="_blank">{{ company.name }}</a>
    </div>
  </footer>
</template>
<script>
import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  Email,
  Google
} from "vue-socialmedia-share";
import Logo from "@/components/Logo";
export default {
  name: 'MainFooter',
  components: {
    Logo,
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Reddit,
    Telegram,
    WhatsApp,
    Email,
    Google
  },
  data() {
    return {
      year: new Date().getFullYear(),
      company: {
        name: 'Thome Legacy Technologies',
        website: 'https://thometechnology.web.app/'
      },
      documents: [
        {
          'name': 'Terms of Use',
          'link': '/legal/terms-of-use.pdf'
        },
        {
          'name': 'Privacy Policy',
          'link': '/legal/privacy-policy.pdf'
        }
      ],
      url: "https://www.thomeimages.com",
      emailUrl: "mailto:info@thomeimages.com",
      socialTitle: "Check out these rare photos on Thome Images",
      socialScale: '2',
    }
  }
}

</script>
<style lang="scss" scoped>
footer {
  margin: auto 0 0;
  background: #073260;
  color: #fff;
  font-size: .9em;

  .info-col {
    .logo {
      margin: 0 0 1rem;
    }

    .info-wrapper {
      height: 100%;

      a {
        color: #ffffff;
        font-weight: 500;
      }

      @media (min-width: 992px) {
        width: 90%;
        padding: 1rem;
        background: rgba(0,0,0,.1);
        border-radius: .25rem;
      }
    }

    p {
      font-size: .875em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .links-col {
    padding: 1.5rem;
    background: rgba(0,0,0,.1);

    .side-text {
      color: rgba(255,255,255,0.5);
      font-size: .875em;
      margin-left: .5rem;
      vertical-align: bottom;

      &::before {
        content: '[';
        margin-right: .1rem;
      }

      &::after {
        content: ']';
        margin-left: .1rem;
      }
    }

    @media (min-width: 992px) {
      padding: 0;
      background: none;
    }
  }

  .social-col {
    .grid-wrapper {
      display: grid;
      grid-template-columns: 32px 32px 32px;
      grid-gap: 10px;

      span {
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
          transition: all 200ms ease-in-out;
        }
      }

      @media (min-width: 992px) {
        float: right;
      }
    }
  }

  h3 {
    margin: 0 0 1.5rem;
    font-size: 1rem;
    text-transform: uppercase;

    @media (min-width: 992px) {
      margin: 0 0 2rem;
    }
  }

  .row {
    display: flex;
    margin: 1.5rem 0;

    @media (min-width: 992px) {
      margin: 3rem 0;
    }
  }

  .links-col,
  .social-col {
    margin-top: 2rem;

    @media (min-width: 992px) {
      margin-top: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 .5rem;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .copyright {
    padding: 1rem;
    font-size: .75em;
    text-align: center;
    color: white;
    background: rgba(0,0,0,.25);

    a {
      text-decoration: none;
      color: white;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (min-width: 992px) {
      font-size: .875em;
    }
  }

}
</style>
