<template>
  <div class="table-responsive mt-3">
    <table class="table table-sm table-condensed table-bordered">
      <tbody>
      <tr v-for="(detail, name) in details" :key="name">
        <td>{{ name }}</td>
        <td>{{ detail }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "ProductInfo",
  props: {
    details: {
      type: Object,
    }
  }
}
</script>
<style scoped>
.table td {
  text-align: left;
}
</style>