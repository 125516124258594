import store from "@/store/index.js"
//import router from "@/router";

export const authGuard = (to, from, next) => {
    const auth = store.state.isLoggedIn;
    window.pintrk('track', 'pagevisit',{
        name: to.url,
        url: next.url,
    });

    const fn = () => {
        if (auth) {
            return next();
        }
        store.commit('setActiveMenuView' , 'signin');
        store.commit('openMenu');
        //router.push({name: "SignIn"})
    };
    if (!auth) {
        return fn();
    }
    return fn()
};
