<template>
  <section class="bg-light">
    <b-container fluid="xl" class="mt-5 mb-5">
      <featured-collections
          :prop-is-admin="false"
          :prop-collections="collections"
      />
    </b-container>

    <b-container fluid="xl" class="mb-5">
      <base-text-block
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <h4>What are Collections?</h4>
        <p>Image collections are a great way to showcase a curated set of images that share a common theme or subject matter. These collections provide an organized way for you to browse through a variety of images in a specific genre.</p>

        <p>
          By grouping images together, it also allows for easy discovery of related images that users may not have thought to search for individually.
        </p>
      </base-text-block>
    </b-container>
  </section>

</template>

<script>
import FeaturedCollections from "@/components/FeaturedCollections.vue";
import BaseTextBlock from "@/components/base/BaseTextBlock";

export default {
  name: "Collections",
  components: {BaseTextBlock, FeaturedCollections},
  data() {
    return {
      collections: [],
    }
  },
  mounted() {
    this.getCollections()
  },
  methods: {
    async getCollections() {
      this.collections = await this.$store.dispatch("collections/getCollections", {published: true})
    },
  },
}
</script>

<style scoped>

</style>