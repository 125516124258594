<!--suppress ALL -->
<template>
  <div class="product-page">
    <div class="masthead">
      <div class="container-xl">
        <search-bar @handleSubmit="gotoImages"/>
      </div>
    </div>
    <div class="product container-xl">
      <div class="product-view">
        <product-viewer :image="image"/>
      </div>
      <div class="product-info">
        <div class="product-info-wrapper rounded">
          <div class="product-heading">
            <h3>Purchase License</h3>
            <p class="text-sm">
              Here is a list of available size options for purchase for this image.
            </p>
          </div>
          <form>
            <product-license
                v-for="(license, index) in licenses"
                :key="index"
                :license="license"
                @setCartItem="setCartItem"
            />
          </form>
          <product-add-cart :cart-item="cartItem" @updateCartCount="updateCartCount"/>
          <product-info :details="image.details"/>
          <product-tag v-for="tag in image.tags" :key="tag" :tag-name="tag"/>
          <product-metadata :image="image"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductAddCart from "@/components/ProductAddCart.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import ProductLicense from "@/components/ProductLicense.vue";
import ProductTag from "@/components/ProductTag.vue";
import ProductViewer from "@/components/ProductViewer.vue";
import ImageService from "@/services/image/ImageService";
import SearchBar from "@/components/SearchBar";
import ProductMetadata from "@/components/ProductMetadata";

export default {
  name: 'Product',
  components: {
    ProductMetadata,
    SearchBar,
    ProductAddCart,
    ProductInfo,
    ProductLicense,
    ProductTag,
    ProductViewer
  },
  async mounted() {
    const imageId = await this.getImageId();
    await this.loadImageAndDetails(imageId);
  },
  data() {
    return {
      imageId: null,
      image: {
        "asset_id": null,
        "details": {},
        "tags": []
      },
      licenses: [],
      cartItem: {
        "image": {
          "id": "",
          "url": ""
        },
        "license": {
          "name": "",
          "price": 0
        }
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
  },
  methods: {
    gotoImages(searchQuery) {
      this.$router.push({name: "Images", params: {"queryString": searchQuery}})
    },
    async getImageId() {
      return this.$route.params.imageId;
    },
    async getImage(imageId) {
      return ImageService.getImage(imageId);
    },
    async loadImageAndDetails(imageId) {
      window.scrollTo(0, 0);
      this.imageId = imageId;
      this.image = await this.getImage(this.imageId);
      this.licenses = await this.getPlanLicenses(this.getImagePlan(this.image));
      await this.setImageTags();
    },
    async setImageTags() {
      const stringTagsPresent = "tags" in this.image && this.image.tags.length > 0 && typeof (this.image.tags) === "string";
      this.image.tags = (stringTagsPresent ? this.image.tags.split(",") : this.image.tags);
    },
    getImagePlan: function (image) {
      return (image.access_mode === "public" || image.access_mode ? "free" : "paid");
    },
    async getPlanLicenses(plan) {
      const secretKey = `${process.env.VUE_APP_STRIPE_SECRET_KEY}`;
      const stripeApiUrl = "https://api.stripe.com/v1";
      const productsUrl = `${stripeApiUrl}/products/search?query=metadata["app"]:"picha"&expand[]=data.default_price`;

      const products = await this.getResourceData(productsUrl, secretKey, "data");
      const licenses = await Promise.all(products.map(async (product) => {
        const productName = product.name;
        const price = product.default_price.unit_amount;
        return {
          "id": product.default_price.id,
          "name": productName,
          "description": product.description,
          "price": parseFloat((price / 100).toString()).toFixed(2),
          "plan": (productName.toLowerCase().includes("free") ? "free" : "paid"),
          "width": product.metadata.width,
          "height": product.metadata.height
        };
      }));
      return licenses.filter((license) => license.plan === plan);
    },
    addLicenceToCartItem(licenseItem) {
      this.cartItem.image = {
        'id': this.imageId,
        'public_id': this.image.public_id,
        'details': this.image.details,
        'url': ImageService.getImageKitUrl(this.image.public_id, {width: '100', height: '100'}),
        'downloadUrl': ImageService.getImageKitUrl(this.image.public_id, {
          width: licenseItem.width,
          height: licenseItem.height
        }),
      };
      this.cartItem.license = licenseItem;
    },
    updateCartCount: function (totalCartItems) {
      this.$emit('updateCartCount', totalCartItems);
    },
    async getResourceData(resourceUrl, token, responseIndex) {
      return fetch(resourceUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }).then(res => res.json()).then(response => {
        return response[responseIndex];
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.product-page {
  background: Radial-gradient(rgba(71, 71, 71, 0.35), rgba(71, 71, 71, 0)), Radial-gradient(at 0 0, #474747, #070707);
}

.product {
  display: block;
  padding-top: 1rem;
  min-height: 100vh;

  @media (min-width: 992px) {
    display: flex;
  }

  > .row {
    min-height: inherit;
  }

  .product-heading {
    h3 {
      font-size: 1.25rem;
      padding: 0 0 0.25em;
      border-bottom: 7px solid #198754;
      border-bottom-right-radius: 0.25em;
    }
  }

  .product-view {
    display: block;
    padding: 1rem;

    @media (min-width: 992px) {
      display: inline-block;
      width: 60%;
      padding: 1rem 0;
    }

    &::v-deep img {
      width: 100%;
      height: auto;
      box-shadow: 1px 5px 15px -2px rgb(0 0 0 / 50%);
    }
  }

  .product-info {
    display: block;
    padding: 1rem;

    @media (min-width: 992px) {
      display: inline-block;
      width: 40%;
      padding: 1rem 0 0 1rem;
    }

    .product-info-wrapper {
      background: white;
      padding: 1rem;

      @media (min-width: 992px) {
        padding: 2rem;
      }
    }
  }
}

.text-sm {
  font-size: .875em;
}

</style>
