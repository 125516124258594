import {DatabaseDAO} from "@/services/base/DatabaseDAO";

class Collections  extends DatabaseDAO{
    constructor() {
        super();
        this.url = 'collections'
    }

    async addCollection(collection) {
        return await this.addRecord(collection, this.url);
    }

    async getCollection(collectionId) {
        return await this.getRecord(collectionId, this.url);
    }

    async updateCollection(collectionId, collection) {
        return await this.updateRecord(collectionId, this.url, collection);
    }

    async deleteCollection(collectionId) {
        return await this.deleteRecord(collectionId, this.url);
    }

    async getCollections(payload) {
        let collections = Object.values(await this.getRecords(this.url));
        if (payload && payload.published) {
            collections = collections.filter(collection => collection.published);
        }
        return collections;
    }

}

export default new Collections();