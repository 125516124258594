<template>
  <span class="badge bg-secondary m-2">{{ tagName }}</span>
</template>
<script>
export default {
  name: "ProductTag",
  props: {
    tagName: {
      type: String,
    }
  }
}
</script>
<style scoped>
</style>
