<template :key="renderKey.related">
  <div class="photo-detail page">
    <div class="container-xl">

      <base-loader v-if="loading" large block/>

      <div v-if="!loading" class="photo-header">
        <div>
          <span
              tabindex="0"
              data-bs-toggle="tooltip"
              class="d-inline-block tooltip-wrapper disabled"
              title="You can only like once logged in!"
          >
            <base-button
                @click.native="addLike"
                variant="outline-secondary"
                :disabled="isDisabled"
            >
              <b-icon icon="hand-thumbs-up-fill"/>
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
              {{ likes }}<span class="visually-hidden">likes</span>
              </span>
            </base-button>
          </span>
        </div>
        <div>
          <base-button
              @click.native="showPurchaseModalFn"
              variant="success"
          >
            Add to Cart
          </base-button>
        </div>
      </div>

      <product-viewer v-if="!loading && image" :image="image" id="product-browser"/>

      <div v-if="!loading && image" class="modal-actions responsive">
        <div class="col-6">
          <p class="d-flex text-wrap">{{ image.title }}</p>

        </div>
        <div class="button-group">

          <base-button
              @click.native="showInfoModal = !showInfoModal"
              variant="outline-secondary"
              small
          >
            <b-icon-info-circle-fill/>
            More info
          </base-button>
          <base-button
              @click.native="showShareModal = !showShareModal"
              variant="outline-secondary"
              small
          >
            <b-icon-share-fill/>
            Share
          </base-button>

        </div>
      </div>

      <div v-if="!loading" class="related">
        <h2 v-if="image">Related photos</h2>
        <gallery :images="relatedImages" @updateCartCount="updateCartCount"/>
      </div>

      <b-modal
          v-if="image"
          v-model="showInfoModal"
          @hide="closeInfoModal"
          ref="modalInfo"
          body-class="modal-info"
          hide-header
          hide-footer
          centered
          no-fade
      >
        <button class="close-x" @click="closeInfoModal">
          <b-icon-x-lg/>
        </button>

        <div class="modal-info-top">
          <b-row>
            <b-col cols="5" class="text-center">
              <img
                  :src="image.url"
                  :alt="image.title"
                  class="modal-info-img"
              />
            </b-col>
            <b-col cols="7">
              <div class="modal-info-heading">
                <h3>Photo details</h3>
                <p>
                  {{ image.title }}
                </p>
              </div>
            </b-col>
          </b-row>

          <div class="modal-info-details">
            <div v-if="image.collection">
              <h5>
                Collection:
              </h5>
              <p>{{ image.collection }}</p>
            </div>

            <div v-if="image.tags">
              <h5>
                Tags:
              </h5>
              <b-badge
                  v-for="tag in image.tags"
                  variant="primary"
                  pill
                  :key="tag">
                {{ tag }}
              </b-badge>
            </div>

            <div v-if="image.description">
              <h5>
                Description:
              </h5>
              <p>{{ image.description }}</p>
            </div>
          </div>

        </div>
      </b-modal>

      <b-modal
          v-if="image"
          v-model="showShareModal"
          @hide="closeShareModal"
          ref="modalShare"
          body-class="modal-share"
          hide-header
          hide-footer
          centered
          no-fade
      >
        <button class="close-x" @click="closeShareModal">
          <b-icon-x-lg/>
        </button>
        <h3>Spread the word</h3>
        <p>Share this image on your social media account</p>

        <SocialSharing
            :url="updatedShareUrl"
            :title="image.title"
            :key="renderKey.sharing"
        />
      </b-modal>

      <b-modal
          v-if="image"
          v-model="showPurchaseModal"
          @hide="closePurchaseModal"
          ref="modalPurchase"
          body-class="modal-purchase"
          hide-header
          hide-footer
          centered
          no-fade
      >
        <button class="close-x" @click="closePurchaseModal">
          <b-icon-x-lg/>
        </button>

        <div class="modal-purchase-top">

          <div class="product-info">
            <div class="product-info-wrapper rounded">
              <div class="product-heading">
                <h3>Purchase License</h3>
                <p class="text-sm">
                  Here is a list of available size options for purchase for this image.
                </p>
              </div>
              <form>
                <product-license
                    v-for="(license, index) in licenses"
                    :key="index"
                    :license="license"
                    @setCartItem="setCartItem"
                />
              </form>

              <div class="product-add-to-cart">
                <h5>${{ cartItem.license.price }}</h5>
                <div class="d-flex flex-row justify-content-between">
                  <div class="btn btn-md btn-primary" @click="addCart">Add to cart</div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {updateImagesToLocalStorage} from "@/assets/common";
import ProductViewer from "@/components/ProductViewer.vue";
import ImageService from "@/services/image/ImageService";
import PageHeader from "@/components/PageHeader";
import SocialSharing from "@/components/SocialSharing";
import {getRandomImages} from "@/utils/RandomUtil";
import BaseButton from "@/components/base/BaseButton";
import ProductLicense from "@/components/ProductLicense.vue";
import Cart from "@/assets/cart.js";
import BaseLoader from "@/components/base/BaseLoader";
import Gallery from "@/components/Gallery";


export default {
  name: "Photo",
  components: {
    Gallery,
    BaseLoader,
    BaseButton,
    PageHeader,
    ProductViewer,
    ProductLicense,
    SocialSharing
  },
  data() {
    return {
      index: 0,
      imageId: null,
      image: {
        title: '',
        description: '',
        secure_url: '',
        likes: []
      },
      images: [],
      hasMoreInfo: false,
      hasSharing: false,
      shareUrl: null,
      updatedShareUrl: null,
      renderKey: {
        sharing: 0,
        related: 0
      },
      numberOfRelatedImages: 20,
      relatedImages: [],
      licenses: [],
      cartItem: {
        "image": {
          "id": "",
          "url": ""
        },
        "license": {
          "name": "",
          "price": 0
        }
      },
      showInfoModal: false,
      showPurchaseModal: false,
      showShareModal: false,
      loading: true,
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  async mounted() {
    this.images = await this.getImages();
    this.imageId = this.getImageId();
    await this.loadImageAndDetails(this.imageId);
    this.shareUrl = await this.getShareUrl(this.image);

    window.gtag('event', 'conversion', {
      'send_to': 'AW-11459263514/W37zCKPWjYQZEJr4mdgq',
      'transaction_id': this.shareUrl
    });
  },
  watch: {
    imageId: async function (newImageId) {
      await this.loadImageAndDetails(newImageId);
    },
    shareUrl: function (newShareUrl) {
      this.updatedShareUrl = newShareUrl;
    }
  },
  computed: {
    likes() {
      if (this.image.likes) {
        return this.image.likes.length;
      }
      return 0;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    loggedInUser() {
      return this.$store.state.user;
    },
    isDisabled() {
      return !this.$store.state.isLoggedIn;
    }
  },
  methods: {
    async showPurchaseModalFn() {
      let image = this.image;
      this.imageId = image.asset_id;
      await this.setImageLicenses(image);
      this.showPurchaseModal = true;
    },
    async setImageLicenses(image) {
      let plan = this.getImagePlan(image);
      this.licenses = await this.getPlanLicenses(plan);
    },
    getImagePlan: function (image) {
      return (image.access_mode === "public" || image.access_mode ? "free" : "paid");
    },
    async getPlanLicenses(plan) {
      const secretKey = `${process.env.VUE_APP_STRIPE_SECRET_KEY}`;
      const stripeApiUrl = "https://api.stripe.com/v1";
      const productsUrl = `${stripeApiUrl}/products/search?query=metadata["app"]:"picha"&expand[]=data.default_price`;

      const products = await this.getResourceData(productsUrl, secretKey, "data");
      const licenses = await Promise.all(products.map(async (product) => {
        const productName = product.name;
        const price = product.default_price.unit_amount;
        return {
          "id": product.default_price.id,
          "name": productName,
          "description": product.description,
          "price": parseFloat((price / 100).toString()).toFixed(2),
          "plan": (productName.toLowerCase().includes("free") ? "free" : "paid"),
          "width": product.metadata.width,
          "height": product.metadata.height
        };
      }));
      return licenses.filter((license) => license.plan === plan);
    },
    setCartItem(licenseItem) {
      this.cartItem.image = {
        'id': this.imageId,
        'public_id': this.image.public_id,
        'details': this.image.details,
        'url': ImageService.getImageKitUrl(this.image.public_id, {width: '100', height: '100'}),
        'downloadUrl': ImageService.getImageKitUrl(this.image.public_id, {
          width: licenseItem.width,
          height: licenseItem.height
        }),
      };
      this.cartItem.license = licenseItem;
    },
    updateCartCount: function (totalCartItems) {
      this.$emit('updateCartCount', totalCartItems);
    },
    async getResourceData(resourceUrl, token, responseIndex) {
      return fetch(resourceUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }).then(res => res.json()).then(response => {
        return response[responseIndex];
      });
    },

    addCart() {
      const isSelected = this.cartItem.license.name !== "";
      if (isSelected) {
        let itemSelected = this.cartItem;
        let itemMatch = Cart.getItem(itemSelected);
        if (itemMatch) {
          this.$toast.error('Item already in Cart!');
        }
        if (!itemMatch) {
          Cart.addItem(itemSelected);
          this.$toast.success('Item added to Cart!');
          this.$emit('updateCartCount', Cart.countItems());
        }
      }
    },
    formatDate(iso) {
      const date = new Date(iso);
      const month = date.toLocaleString('default', {month: 'long'});
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month} ${day}, ${year}`;
    },
    closeInfoModal() {
      this.showInfoModal = false;
    },
    closePurchaseModal() {
      this.showPurchaseModal = false;
    },
    closeShareModal() {
      this.showShareModal = false;
    },
    async getImage(imageId) {
      return this.images.find(image => image.asset_id === imageId);
    },
    getImageId() {
      let imageId = this.$route.params.imageId;
      if (imageId) {
        return imageId;
      }
      return this.images[this.index].asset_id;
    },
    getImageIndex(imageId) {
      const isCurrentImage = (image) => image.asset_id === imageId;
      return this.images.findIndex(isCurrentImage);
    },
    async getImages() {
      let images = await ImageService.getImages();
      return images != null ? images : [];
    },
    getImageLikes(image) {
      if (["number"].includes(typeof image.likes)) {
        return [];
      }
      return image.likes;
    },
    async getShareUrl(image) {
      return `${window.location.host}/photo/${image.asset_id}`;
    },
    replaceRoutePath(imageId) {
      this.$router.replace({
        path: `/photo/${imageId}`
      }).catch(err => {
        console.error(err);
      });
    },
    getWaterMarkTransformString(secureUrl, transformationString) {
      return secureUrl.split("upload").join(transformationString);
    },
    toggleMoreInfo(hasMoreInfo) {
      this.hasMoreInfo = !hasMoreInfo;
    },
    toggleSharing(hasSharing) {
      this.hasSharing = !hasSharing;
    },
    async addLike() {
      const userEmail = this.loggedInUser.email;
      const imageLikes = new Set(this.image.likes);
      const hasLiked = imageLikes.has(userEmail);

      if (!hasLiked) {
        imageLikes.add(userEmail);
      }
      if (hasLiked) {
        imageLikes.delete(userEmail);
      }

      this.image.likes = [...imageLikes];
      await ImageService.updateImage(this.index, this.image);
      await this.updateLocalStorageImages(this.index, {likes: this.image.likes});
    },
    updateLocalStorageImages(index, updates) {
      const image = this.images[index];
      this.images[index] = {...image, ...updates};
      updateImagesToLocalStorage(this.images);
    },
    async loadImageAndDetails(imageId) {
      window.scrollTo(0, 0);
      this.loading = true;
      this.imageId = imageId;
      this.index = await this.getImageIndex(this.imageId);
      this.image = await this.getImage(this.imageId);
      this.image.likes = await this.getImageLikes(this.image);
      this.shareUrl = await this.getShareUrl(this.image);
      this.relatedImages = await this.getRelatedImages(this.image.collection, this.imageId, this.numberOfRelatedImages);
      this.renderKey.sharing++;
      this.renderKey.related++;
      this.loading = false;
    },
    async getRelatedImages(collection, imageId, numberOfImages) {
      let relatedImages = await ImageService.getImagesByCollection(collection);
      relatedImages = relatedImages.filter(relatedImage => relatedImage.asset_id !== imageId);
      return (relatedImages.length > numberOfImages ? getRandomImages(relatedImages, numberOfImages) : relatedImages);
    },
    async handleRelatedClick(imageId) {
      await this.loadImageAndDetails(imageId);
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 819px) {
  .hidden-md-down {
    display: none;
  }
}

.photo-header {
  display: flex;
  justify-content: space-between;
  padding: .5rem 0;

  @media (min-width: 768px) {
    padding: 1.5rem 0;
  }

  &.responsive {
    display: block;

    > div {
      margin: 0 0 .5rem;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;

      > div {
        margin: 0;
      }
    }
  }

  p {
    color: #6c757d;
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

}

.information {
  overflow: hidden;
  max-width: 600px;
  margin: .5rem auto;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);

  p {
    white-space: normal;
    font-size: .9em;
  }

  .desc {
    font-weight: 700;
    color: #fdc60d;
  }
}

.actions {
  position: relative;
  margin: 0 auto;
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}

.related {
  margin: 1.5rem 0 0;

  @media (min-width: 768px) {
    margin: 2rem 0 0;
  }
}
</style>
