import {child, get, onValue, ref, set, update, remove} from "firebase/database";
import {database} from "../../../firebaseDatabaseConfig";
import {v4 as uuidv4} from 'uuid';

export class DatabaseDAO {


    constructor() {
        this.enviroment = process.env.VUE_APP_DEPLOYMENT_ENV;
    }


    /**
     *
     * @param record
     * @param recordUrl
     * @returns {Promise<void>}
     */
    addRecord(record, recordUrl) {
        if (!record.id) {
            record.id = uuidv4();
        }

        const saveRecordUrl = `${this.enviroment}/${recordUrl}/${record.id}`;

        return set(ref(database, saveRecordUrl), record);
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @param record
     * @returns {Promise<void>}
     */
    async updateRecord(recordId, recordUrl, record) {
        const saveRecordUrl = `${this.enviroment}/${recordUrl}/${recordId}`;
        const updates = {};
        let existing = await this.getRecord(recordId, recordUrl)
        updates[saveRecordUrl] = {...existing, ...record};
        updates['updated_at'] = new Date().toISOString();
        return update(ref(database), updates);
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @returns {Promise<void>}
     */
    deleteRecord(recordId, recordUrl) {
        return remove(ref(database, `${this.enviroment}/${recordUrl}/${recordId}`));
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @returns {Promise<DataSnapshot>}
     */
    getRecord(recordId, recordUrl) {
        const dbRef = ref(database);
        const saveRecordUrl = `${this.enviroment}/${recordUrl}/${recordId}`;
        console.log(saveRecordUrl)

        return get(child(dbRef, saveRecordUrl))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    console.log("No data available");
                    return {};
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /**
     *
     * @param recordUrl
     * @returns {Promise<DataSnapshot>}
     */

    getRecords(recordUrl) {
        const dbRef = ref(database);
        return get(child(dbRef, `${this.enviroment}/${recordUrl}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    console.log("No data available");
                    return [];
                }
            })
            .catch((error) => {
                console.log("There is an error");
                console.error(error);
            });
    }

    listenRecordChanges(recordUrl, callback) {
        const recordRef = ref(database, `${this.enviroment}/${recordUrl}`);
        onValue(recordRef, (snapshot) => {
            return callback(snapshot.val());
        });
    }
}

export default new DatabaseDAO();
