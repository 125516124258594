import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import Toast, {POSITION} from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

import VueTippy, {TippyComponent} from "vue-tippy";

import plugin, {setGlobalConfig} from "@serializedowen/vue-img-watermark";

import VueLazyload from "vue-lazyload";
import VueLazyBackgroundImage from 'vue-lazy-background-images/VueLazyBackgroundImage';
Vue.component('lazy-background', VueLazyBackgroundImage);

import mixpanel from 'mixpanel-browser';

mixpanel.init('7377f2d72887aa54f9af21b99cfbd593', {debug: true});
mixpanel.set_config({
    debug: true,
    ignore_dnt: true,
})
mixpanel.track('Sign up');


// Import the plugin here
import {Auth0Plugin} from './auth';

import VueSocialSharing from 'vue-social-sharing';

const clientId = process.env.VUE_APP_AUTH_CLIENT_ID
const domain = process.env.VUE_APP_AUTH_DOMAIN

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});

Vue.use(plugin);
Vue.use(VueLazyload);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

setGlobalConfig({
    mode: "fill",
    textBaseline: "middle",
    font: "10px Arial",
    fillStyle: "thistle",
    content: "",
    rotate: 37
});

Vue.use(VueTippy, {
    directive: "tippy",
    flipDuration: 0,
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false
            }
        }
    }
});

Vue.component("tippy", TippyComponent);

Vue.config.productionTip = false

const toastOptions = {
    position: POSITION.TOP_CENTER
};


Vue.use(Toast, toastOptions);

Vue.use(VueSocialSharing);

new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount("#app")
