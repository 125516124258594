<template>
  <div class="featured-collections">
    <b-row class="scrollable-row">
      <b-col
          v-for="collection in collections"
          :key="collection.id"
          md="6"
          lg="4"
      >
        <div

            @click="goToCollection(collection)"
            class="collection-box"

        >
          <img :src="collection.thumbnails"
               srcset=""
               class="image-featured">
          <div class="collection-row" v-if="collection.images">
            <img :src="collection.images[0].thumbnails"
                 srcset=""
                 class="image-secondary">
            <img :src="collection.images[1].thumbnails"
                 srcset=""
                 class="image-secondary">
            <div class="image-secondary image-wrapper">
              <img :src="collection.images[2].thumbnails"
                   srcset=""
                   class="image-wrapper-img">
              <h3 class="label">
                +{{ collection.size }}
              </h3>
            </div>
          </div>
          <h4 class="collection-name">
            {{ collection.name }}
          </h4>
          <b-button class="m-1" v-if="propIsAdmin" variant="danger" size="sm" @click="deleteCollection(collection.id)">Delete
          </b-button>
          <b-button  class="m-1" v-if="propIsAdmin" variant="primary"  size="sm" @click="editCollection(collection)">Edit</b-button>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import BaseCard from "@/components/base/BaseCard";

export default {
  name: "FeaturedCollections",
  components: {BaseCard},
  props: {
    propCollections: {
      type: Array,
      required: true
    },
    propIsAdmin: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      collections: [],
      renderKey: 0,
      render: true,
      isAdmin: this.propIsAdmin
    }
  },
  watch: {
    propCollections: {
      handler: function (newVal, oldVal) {
        this.collections = newVal
        this.renderKey++
      },
      deep: true
    }
  },
  methods: {
    goToCollection(collection) {
      if(this.isAdmin) {
        this.$emit('edit-collection', collection)
        return
      }
      this.$router.push({name: 'Images', query: {search: collection.searchQuery}})
    },
    editCollection(collection) {
      this.$emit('edit-collection', collection)
    },
    deleteCollection(id) {
      this.$emit('delete-collection', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-box {
  position: relative;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  //transition: filter .25s ease;
  text-decoration: none;
  background-color: transparent;

  //transition: .3s ease-in-out;

  margin: 0 auto;

  &:focus,
  &:hover {
    filter: brightness(.7);
    transition: filter .25s ease;
  }

  @media (min-width: 768px) {
    min-width: 350px;
    max-width: 390px;
  }
}

.collection-name {
  display: block;
  margin-top: 20px;
  padding: 0;
  padding-right: unset;
  padding-left: unset;
  border: none;
  outline: none;
  background: 0 0;

  color: #2c343e;
  fill: #2c343e;

  font-weight: 600;
  font-size: 23px;
  line-height: 36px;
  letter-spacing: -.015em;

  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image-featured {
  position: relative;
  width: 100%;
  background: #dfdfe0;
  overflow: hidden;
  margin: 0 0 2px;
  min-width: 100%;
  height: 265px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.collection-row {
  display: flex;
  height: 100px;
  margin-top: 2px;
}

.image-secondary {
  position: relative;
  background: #dfdfe0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;

  &:first-child {
    border-bottom-left-radius: 20px;
  }

  &:nth-child(2) {
    margin: 0 4px;
  }

  &:last-child {
    border-bottom-right-radius: 20px;
  }
}

.image-wrapper {

  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: block;
    margin: 0;
    padding: 0;
    padding-right: unset;
    padding-left: unset;
    border: none;
    outline: none;
    background: 0 0;

    color: #fff;
    fill: #fff;

    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -.02em;
  }

  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, .2);
  }
}

.featured {
  background-color: #f2f7f9;
}


.button {
  background: #fec70a;
  margin-top: 1rem;
  box-shadow: none;
  border: none;
  color: black;

}

.button:hover {
  background: #f37033;
  box-shadow: none;
}

.scrollable-row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0 -15px;
}

</style>