<template>
  <div class="product-viewer">
    <div class="ik-image">
      <IKImage urlEndpoint="https://ik.imagekit.io/l7yvb6qgi/" :path="getFilename(image.public_id)"
               :transformation="transformation"/>
    </div>

  </div>
</template>
<script>
import ImageService from "@/services/image/ImageService";
import {IKImage} from "imagekitio-vue";

export default {
  name: "ProductViewer",
  components: {IKImage},
  props: {
    image: {
      type: Object,
    }
  },
  data() {
    return {
      ImageService: ImageService,
      transformation: [{
        "height": "600",
        "width": "600",
        "crop": "force"
      }]
    }
  },
  methods: {
    getFilename(filePath) {
      return (filePath !== undefined ? filePath.split('/').pop() : "");

    },
  },
}
</script>
<style lang="scss" scoped>
.product-viewer {
  max-width: 100%;
}

.product-viewer .d-flex {
  justify-content: center;
  align-items: center;
  width: 60%;
}

.ik-image {
  width: 600px;
}

@media (max-width: 768px) {

  .ik-image {
    width: 600px;
    height: 600px;
  }
}

@media (max-width: 1176px) {

  .ik-image {
    width: 600px;
    height: 600px;
  }
}


</style>
