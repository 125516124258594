<template>
  <b-card
    :class="[theme, align]"
    :img-src="imgSrc"
    :img-alt="imgAlt"
    img-left
  >
    <slot />
  </b-card>
</template>
<script>

export default {
  name: "Card",
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    imgAlt: {
      type: String,
    },
    theme: {
      type: String,
      default: 'default',
    },
    align: {
      type: String,
      default: 'left',
    }
  },
}
</script>
<style lang="scss" scoped>
.card {
  margin-bottom: 1rem;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

//themes
.reverse {
  border: 0;
  background: #f1f1f1;
}

.message {
  border: 0;
  background: #f1f1f1;
}
</style>
