<template>
  <form>
    <div>
      <div class="form-row">
        <label class="form-label">New Password</label>
        <input type="password" class="form-control" placeholder="Password"
               aria-describedby="emailHelp" v-model="user.password">
      </div>
      <div class="form-row">
        <label class="form-label">Confirm New Password</label>
        <input type="password" class="form-control" placeholder="Password"
               aria-describedby="emailHelp" v-model="user.confirmPassword">
      </div>
    </div>
    <div class="form-row form-actions">
      <div class="mt-3 d-flex flex-column">
        <div class="btn btn-outline-danger" @click="changePassword">Continue
        </div>
      </div>
    </div>
  </form>

</template>

<script>
import AccountsService from "@/services/accounts/AccountsService";

export default {
  name: "ChangePassword",
  data() {
    return {
      user: {
        password: "",
        confirmPassword: ""
      }
    }
  },
  methods: {
    confirmPasswordViability() {
      if (this.user.password.length < 8) {
        this.$toast.error("The password must be more than 8 characters")
        return false
      }
      if (this.user.password !== this.user.confirmPassword) {
        this.$toast.error("The new password must be the same as the confirm new password")
        return false
      }
      return true
    },
    async changePassword() {
      let user = this.$store.state.user
      if (!user.email) {
        this.$toast.error("Unexpected error occurred. Please refresh browser and retry again")
      }
      if (this.confirmPasswordViability()) {
        delete this.user.confirmPassword
        await AccountsService.changePassword(user.id, this.user)
        this.$toast.success("Password has been changes successfully. Please sign in.")
        this.$store.commit("setUserDetails", {})
        this.$store.commit("setLoggedInStatus", false)
        this.$store.commit("setActiveMenuView", 'signin')
        await this.$router.push({name: "Home"})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
