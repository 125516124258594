<template>
  <section class="section-hero">
    <div class="container">
      <h1>Your source for rare and authentic photography.</h1>
      <div class="mx-auto">
        <p class="lead mb-4">
          Find your next inspiration.
        </p>
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'SectionHero',
}
</script>

<style lang="scss" scoped>
.section-hero {
  padding: 2rem 0 3rem;
  color: #fff;
  background-size: cover;
  background-position: 0 3%;
  background-blend-mode: multiply;

  .container {
    max-width: 640px;
  }

  @media (min-width: 992px) {
    padding: 4rem 0 4rem;
  }

  h1 {
    font-size: 1.5rem;

    @media (min-width: 992px) {
      font-size: 2rem;
    }
  }

  .btn,
  input {
    &:focus {
      box-shadow: none;
    }
  }

}
</style>