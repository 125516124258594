import {generateHash} from "@/utils/HashUtil";
import AccountsDao from "@/services/accounts/AccountsDao";
import defaultUserPermissions from "@/services/accounts/PermissionsConfig";


class AccountsService {
    constructor() {
        this.recordUrl = `accounts`
    }

    existingUserMessage() {
        return {
            status: "error",
            message: "Account already existing"
        }
    }

    async createUserAccount(userObject) {
        let email = userObject.email
        userObject.id = generateHash(email)
        userObject.password = generateHash(userObject.password)
        if (await this.checkExistingAccount(email)) {
            return this.existingUserMessage()
        }
        delete userObject.confirmPassword
        userObject.permissions = defaultUserPermissions
        await AccountsDao.addRecord(userObject, this.recordUrl)
        return {
            status: "success",
            message: "Account created successfully",
            id: userObject.id
        }
    }

    async changePassword(userId, user) {
        user.password = generateHash(user.password)
        await AccountsDao.updateRecord(userId, this.recordUrl, user)

    }
    async updateUserAccountDetails(userId, user) {
        await AccountsDao.updateRecord(userId, this.recordUrl, user)
    }

    async checkExistingAccount(email) {
        let user = await this.getUserAccountDetails(email)
        return user.email === email;
    }

    async getUserAccountDetails(email) {
        let id = generateHash(email)
        return await AccountsDao.getRecord(id, this.recordUrl)
    }

    async signInWithEmailPassword(email, password) {
        let user = await this.getUserAccountDetails(email)
        let hashedPassword = generateHash(password)
        if (hashedPassword === user.password) {
            delete user.password
            return user
        }
        return {
            status: "error",
            message: "Invalid credentials"
        }
    }


}

export default new AccountsService();
