<template :key="renderKey.related">
  <div class="browse page bg-dark bg-gradient text-white">
    <page-header title="Browse"/>
    <div class="page container-xl">
      <span id="swipe-span">
      <product-viewer id="product-browser" v-if="image" :image="image"/>
      </span>
      <div class="information">
        <div class="actions d-flex justify-content-center">
          <button class="btn btn-outline-warning mx-2" @click="toggleMoreInfo(hasMoreInfo)">
            <b-icon icon="info-circle"/>
          </button>
          <span tabindex="0" data-bs-toggle="tooltip" class="d-inline-block tooltip-wrapper disabled"
                title="You can only like once logged in!">
            <button
                type="button" class="btn btn-outline-success position-relative mx-2"
                :disabled="isDisabled" @click="addLike">
              <b-icon icon="hand-thumbs-up-fill"/>
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
              {{ this.likes }}<span class="visually-hidden">likes</span>
            </span>
            </button>
          </span>
          <button class="btn btn-outline-primary mx-2" @click="addToCart">
            <b-icon icon="cart-plus"/>
          </button>
          <button v-if="image.access_mode" class="btn btn-outline-info mx-2" @click="toggleSharing(hasSharing)">
            <b-icon icon="share"/>
          </button>
        </div>
        <div v-if="hasMoreInfo" class="more-info mt-3">
          <p><span class="desc">Title:</span> {{ image.title }}</p>
          <p v-if="image.description"><span class="desc">Description:</span> {{ image.description }}</p>
          <p v-if="image.tags.length > 0"><span class="desc">Tags:</span> {{ image.tags.join(",").toString() }}</p>
        </div>
        <div v-if="hasSharing" class="sharing d-flex justify-content-center mt-3">
          <SocialSharing :share-url="shareUrl" :share-title="image.title" :key="renderKey.sharing"/>
        </div>
      </div>
      <div class="related">
        <product-related :related-images="relatedImages" @handleRelatedClick="handleRelatedClick"/>
      </div>
      <button
          class="arrow-btn left btn btn-outline hidden-md-down"
          @click="moveToImage('backward')"
          :disabled="index <= 0"
      >
        <b-icon icon="arrow-left-square-fill"/>
      </button>
      <button
          class="arrow-btn right btn btn-outline hidden-md-down"
          @click="moveToImage('forward')"
          :disabled="index >= images.length"
      >
        <b-icon icon="arrow-right-square-fill"/>
      </button>
    </div>
  </div>
</template>
<script>
import {updateImagesToLocalStorage} from "@/assets/common";
import ProductRelated from "@/components/ProductRelated";
import ProductViewer from "@/components/ProductViewer.vue";
import ImageService from "@/services/image/ImageService";
import PageHeader from "@/components/PageHeader";
import SocialSharing from "@/components/SocialSharing";
import ZingTouch from 'zingtouch';
import {getRandomImages} from "@/utils/RandomUtil";

export default {
  name: "Browse",
  components: {
    PageHeader,
    ProductRelated,
    ProductViewer,
    SocialSharing
  },
  async mounted() {
    this.activateSwipe();
    this.images = await this.getImages();
    this.imageId = this.getImageId();
    await this.loadImageAndDetails(this.imageId);
  },
  data() {
    return {
      index: 0,
      imageId: null,
      image: {
        title: '',
        description: '',
        secure_url: '',
        likes: []
      },
      images: [],
      hasMoreInfo: false,
      hasSharing: false,
      shareUrl: null,
      renderKey: {
        sharing: 0,
        related: 0
      },
      numberOfRelatedImages: 6,
      relatedImages: []
    }
  },
  computed: {
    likes() {
      if (this.image.likes) {
        return this.image.likes.length;
      }
      return 0;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    loggedInUser() {
      return this.$store.state.user;
    },
    isDisabled() {
      return !this.$store.state.isLoggedIn;
    }
  },
  methods: {
    async getImage(imageId) {
      return this.images.find(image => image.asset_id === imageId);
    },
    getImageId() {
      let imageId = this.$route.params.imageId;
      if (imageId) {
        return imageId;
      }
      return this.images[this.index].asset_id;
    },
    getImageIndex(imageId) {
      const isCurrentImage = (image) => image.asset_id === imageId;
      return this.images.findIndex(isCurrentImage);
    },
    activateSwipe() {
      const $vm = this;
      let zt = new ZingTouch.Region(document.getElementById('swipe-span'));
      let productBrowser = document.getElementById('product-browser');
      zt.bind(productBrowser, 'swipe', function (e) {
        const eventData = e.detail.data[0];
        const currentDirection = eventData.currentDirection;
        if (currentDirection >= 180 && currentDirection < 270) {
          //Move Right to Left
          $vm.moveToImage('forward');
        }
        if (currentDirection >= 270) {
          //Move Left to Right
          $vm.moveToImage('backward');
        }
      }, false);
    },
    async getImages() {
      let images = await ImageService.getImages();
      return images != null ? images : [];
    },
    getImageLikes(image) {
      if (["number"].includes(typeof image.likes)) {
        return [];
      }
      return image.likes;
    },
    async getShareUrl(image) {
      return `${window.location.origin}/browse/${image.asset_id}`;
    },
    replaceRoutePath(imageId) {
      this.$router.replace({
        path: `/browse/${imageId}`
      }).catch(err => {
        console.error(err);
      });
    },
    async moveToImage(direction) {
      (direction === 'backward') ? this.index-- : this.index++;
      if (this.index < 0) {
        this.index = 0
      }
      if (this.index >= this.images.length) {
        this.index = this.images.length;
      }
      this.imageId = this.images[this.index].asset_id;
      this.replaceRoutePath(this.imageId);
      await this.loadImageAndDetails(this.imageId);
    },
    getWaterMarkTransformString(secureUrl, transformationString) {
      return secureUrl.split("upload").join(transformationString);
    },
    toggleMoreInfo(hasMoreInfo) {
      this.hasMoreInfo = !hasMoreInfo;
    },
    toggleSharing(hasSharing) {
      this.hasSharing = !hasSharing;
    },
    addToCart() {
      this.$store.commit("setCurrentProductImageIndex", this.index);
      this.$store.commit("setCurrentBrowseImageIndex", this.index);
      this.$router.push({
        name: 'Product',
        params: {imageId: this.image.asset_id, image: this.image, imageIndex: this.index}
      });
    },
    async addLike() {
      const userEmail = this.loggedInUser.email;
      const imageLikes = new Set(this.image.likes);
      const hasLiked = imageLikes.has(userEmail);

      if (!hasLiked) {
        imageLikes.add(userEmail);
      }
      if (hasLiked) {
        imageLikes.delete(userEmail);
      }

      this.image.likes = [...imageLikes];
      await ImageService.updateImage(this.index, this.image);
      await this.updateLocalStorageImages(this.index, {likes: this.image.likes});
    },
    updateLocalStorageImages(index, updates) {
      const image = this.images[index];
      this.images[index] = {...image, ...updates};
      updateImagesToLocalStorage(this.images);
    },
    async loadImageAndDetails(imageId) {
      window.scrollTo(0, 0);
      this.imageId = imageId;
      this.index = await this.getImageIndex(this.imageId);
      this.image = await this.getImage(this.imageId);
      this.image.likes = await this.getImageLikes(this.image);
      this.shareUrl = await this.getShareUrl(this.image);
      this.relatedImages = await this.getRelatedImages(this.image.collection, this.imageId, this.numberOfRelatedImages);
      this.renderKey.sharing++;
      this.renderKey.related++;
    },
    async getRelatedImages(collection, imageId, numberOfImages) {
      let relatedImages = await ImageService.getImagesByCollection(collection);
      relatedImages = relatedImages.filter(relatedImage => relatedImage.asset_id !== imageId);
      return (relatedImages.length > numberOfImages ? getRandomImages(relatedImages, numberOfImages) : relatedImages);
    },
    async handleRelatedClick(imageId) {
      await this.loadImageAndDetails(imageId);
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 819px) {
  .hidden-md-down {
    display: none;
  }
}

.arrow-btn {
  position: absolute;
  top: 9rem;
  padding: 0;
  margin: 0 1rem;
  font-size: 3rem;
  background: transparent;
  border: 0;

  @media (min-width: 390px) {
    top: 12rem;
  }

  @media (min-width: 500px) {
    top: 14rem;
  }

  @media (min-width: 768px) {
    top: 16rem;
  }

  &.right {
    right: 0;
  }

  .bi {
    fill: #fdc60d;
  }

  &:hover {
    .bi {
      fill: #f6d257;
    }
  }
}

.information {
  overflow: hidden;
  max-width: 600px;
  margin: .5rem auto;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);

  p {
    white-space: normal;
    font-size: .9em;
  }

  .desc {
    font-weight: 700;
    color: #fdc60d;
  }
}

.actions {
  position: relative;
  margin: 0 auto;
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}
</style>
