<template>
  <header
    :class="{
      default: theme === 'default',
      dark: theme === 'dark',
      light: theme === 'light',
      'no-margin': noMargin
    }"
  >
    <div class="container-xl">
      <h2 data-aos="fade-down">{{title}}</h2>
      <p v-if="description" data-aos="fade-up">{{description}}</p>
      <slot class="slot" />
    </div>
  </header>
</template>
<script>

export default {
  name: "PageHeader",
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    theme: {
      type: String,
      default: 'default',
    },
    noMargin: {
      type: Boolean,
      default: false,
    }
  },
}

</script>
<style lang="scss" scoped>
header {

  .slot {
    margin-top: 2rem;
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 1.5rem;
    }
  }

  &.default {
    margin: 0 0 2rem;
    padding: 0;
    border-bottom: 2px solid #fdc60d;

    &.no-margin {
      margin: 0;
    }

    h2 {
      margin: 0 0 .25em;
    }

    p {
      color: #777777;
      font-size: .875em;
    }
  }

  &.light {
    padding: 2rem 0;
    color: #030303;
    background: rgba(255,255,255, 0.1);

    h2 {
      margin: 0 0 .25em;
    }

    p {
      font-size: .875em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: 992px) {
      padding: 3rem 0;
    }
  }

  &.dark {
    padding: 2rem 0;
    color: #ffffff;
    background: #083260;

    h2 {
      margin: 0 0 .25em;
    }

    p {
      color: #f1f2f3;
      font-size: .875em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: 992px) {
      padding: 3rem 0;
    }
  }
}
</style>
