<template>
  <div class="container-fluid">
    <div class="row">
      <div class="grid">
        <div v-for="(image, index) in images"
             :key="index" class="grid-item card" @click="viewImage(image, index)">
          <IKImage urlEndpoint="https://ik.imagekit.io/l7yvb6qgi/" :path="getFilename(image.public_id)"
                   :transformation="transformation" :class="col-1"/>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageService from "@/services/image/ImageService";
import router from "@/router";
import {IKImage} from "imagekitio-vue";

export default {
  name: "GalleryAdmin",
  components: {IKImage},
  data() {
    return {
      ImageService: ImageService,
      images: this.propImages,
      transformation: {
        height: 200,
        width: 200,
        crop: "force"
      }
    }
  },
  props: {
    propImages: Array
  },
  methods: {
    getFilename(filePath) {
      return (filePath !== undefined ? filePath.split('/').pop() : "");
    },
    viewImage(image, id) {
      this.$store.commit("setCurrentProductImageIndex", id);
      router.push({name: 'ImagesEditingAdmin', params: {imageId: image.asset_id, image: image, imageIndex: id}});
    }
  }
}
</script>
<style lang="scss" scoped>
$timing: 300ms;
.col {
  position: relative;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.015);

    .image-info {
      opacity: 1;
    }
  }
}

.image-info {
  position: absolute;
  padding: 20px;
  font-size: .75em;
  color: white;
  background: rgba(0, 0, 0, .2);
  width: auto;
  margin: 0 .75rem;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 250ms ease-in-out;
}


::v-deep {
  .carousel-caption {
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px;
    color: inherit;
    text-align: left;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 2rem;
    top: 300px;
  }

  .carousel-control-prev {
    left: -140px;
  }

  .carousel-control-next {
    right: -140px;
  }
}

.slide {
  .slide-img {
    text-align: center;

    img {
      margin: 0 auto;
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
        height: 650px;
      }
    }
  }
}

.placeholder-img {
  height: 100%;
  cursor: pointer;
  background-position: 50% 50%;
  border-radius: 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(180px, auto);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(240px, auto);
  }
}

.grid-mixed {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 300px 300px;

    .grid-item {
      background: #fec70a;

      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 300px 300px 300px 300px;

    .grid-item {
      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }

  }
}

.card {
  min-height: 100px;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
  border: 0;
  transition: all $timing ease-in-out;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    opacity: 0;
    color: #fff;
    font-family: 'Bootstrap-icons';
    font-size: 26px;
    content: '\F52A';
    pointer-events: none;
    z-index: 9000;
    transition: all $timing ease-in-out;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(46, 132, 206, 0.7);
    content: '';
    cursor: pointer;
    pointer-events: none;
    transition: all $timing ease-in-out;
  }

  &:hover {
    transform: scale(1.025);

    &::before {
      animation: fly-in $timing forwards;
    }

    &::after {
      opacity: 1;
    }
  }
}

@keyframes fly-in {
  from {
    opacity: 0;
    left: 100%;
    transform: scale(5) rotate(-360deg);
  }
  to {
    opacity: 1;
    left: 50%;
    transform: scale(1) rotate(0);
  }
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}

.btn {
  position: relative;
}


</style>
