<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 d-flex justify-content-center"
           v-for="(image, index) in relatedImages" :key="index">
         <img
            v-watermark
            alt="A thome image collection"
            class="main-image"
            :src="ImageService().getImageKitUrl(image.public_id, {'width': '200', 'height': '200'})"
            @click="handleRelatedClick(image.public_id)"
            width="200"
            height="200"
        >
      </div>
    </div>
  </div>
</template>
<script>

import ImageService from "@/services/image/ImageService";

export default {
  name: "ProductRelated",
  components: {
  },
  props: {
    relatedImages: Array
  },
  methods: {
    ImageService() {
      return ImageService
    },
    handleRelatedClick(imageId) {
      this.$emit("handleRelatedClick", imageId);
    }
  }
}
</script>
<style scoped>
</style>
