import {child, getDatabase, ref} from "firebase/database";
import {firebaseApp} from "./firebaseConfig";


export const database = getDatabase(firebaseApp, "https://picha.firebaseio.com/");
export const dbRef = ref(database);


export const imagesChildRef = child(dbRef, "photos")
export const cartChildRef = child(dbRef, "cart")


export default {
    dbRef,
    imagesChildRef,
    database,
    cartChildRef
};
