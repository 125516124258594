export function saveImagesToLocalStorage(images) {
    images = images.length > 0 ? images : [];
    sessionStorage.setItem('pichaImageLinks', JSON.stringify(images.slice(2)));
}

export function getImagesFromLocalStorage() {
    const retrievedObject = sessionStorage.getItem('pichaImageLinks');
    return (retrievedObject == null ? [] : JSON.parse(retrievedObject));
}

export function updateImagesToLocalStorage(images) {
    sessionStorage.setItem('pichaImageLinks', JSON.stringify(images));
}

export function clearImagesFromLocalStorage() {
    sessionStorage.removeItem(`pichaImageLinks`)
}

export function contextMenuListener() {
    window.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    }, false);
}


export default {
    saveImagesToLocalStorage,
    contextMenuListener
}
