<template>
  <div class="page container-xl d-flex flex-column justify-content-start" >
    <page-header
        title="My Orders"
        description="View all of your past orders."
    />
    <div>
      <order-item
          v-for="(order, index) in orders"
          :key="index"
          :order-id="extractOrderId(order.id)"
          :order-item-number="order.items.length"
          :order-amount="getOrderAmount(order)"
      />
    </div>
  </div>

</template>

<script>
import OrderService from "@/services/order/OrderService";
import OrderItem from "@/components/OrderItem";
import PageHeader from "@/components/PageHeader";

export default {
  name: "Orders",
  components: {PageHeader, OrderItem},
  data() {
    return {
      orders: []
    }
  },
  mounted() {
    this.getPastOrders()
  },
  methods: {
    getOrderAmount(order) {
      let items = order.items
      const initialValue = 0;
      let sumOrderAmount = 0
      items.reduce(
          function (_previousItem, currentItem) {
            sumOrderAmount += parseFloat(currentItem.license.price)
          },
          initialValue
      );

      return parseFloat(sumOrderAmount.toFixed(2))
    },
    extractOrderId(orderId) {
      return orderId.split("/")[1]
    },
    async getPastOrders() {
      let user = this.$store.state.user
      let orders = await OrderService.getUserOrders(user.id)
      this.orders = Object.values(orders)
    }
  }
}
</script>

<style lang="scss" scoped>
.orders {
  padding: 2rem;
  margin: 0 0 .5rem;
}
</style>
