import ImageDao from "./ImageDao";
import {getImageKitUrl} from "@/utils/ImageKitUtil";
import {getRandomImages} from "@/utils/RandomUtil";

/**
 * @name ImageService
 * @description Service to manage Images
 * @class
 * @public
 */

class ImageService {

    /**
     * Add Image.
     *
     * @param {string} imageIndex image Index
     * @param {object} image  image to add
     * @return {object} added image
     */
    addImage(imageIndex, image) {
        return ImageDao.addImage(imageIndex, image);
    }

    /**
     * Update image
     *
     * @param {string} imageId The imageId to update.
     * @param {object} image The image data to update.
     * @return {object} updated image.
     */
    updateImage(imageId, image) {
        return ImageDao.updateImage(imageId, image);
    }

    /**
     * Delete image
     *
     * @param {string} imageId The imageId to delete.
     * @return {object} deleted image.
     */
    deleteImage(imageId) {
        return ImageDao.deleteImage(imageId);
    }

    /**
     * Get image
     *
     * @param {string} imageId The imageId to fetch.
     * @return {promise} selected image.
     */
    async getImage(imageId) {
        const images = Object.values(await this.getImages());
        return images.find(image => image.asset_id === imageId);
    }

    /**
     * Get all images
     *
     * @return {promise} all images.
     */
    async getImages() {
        let images = await ImageDao.getImages();
        return this.getDefaultCollectionAttr(images);
    }


    /**
     * Get images by likes
     *
     * @param {promise} images The images to be updated with collection attribute
     * @return {array} updated images.
     */
    async getDefaultCollectionAttr(images) {
        return Object.values(images).map(image => {
            const freeAttribute = ("collection" in image);
            if (!freeAttribute) {
                image.collection = "";
            }
            return image;
        });
    }

    /**
     * Get images by likes
     *
     * @param {number} imageLimit The number of images to return.
     * @param {string} liked The order of liked images to return.
     * @return {array} liked images.
     */
    async getImagesByLikes(imageLimit = 5, liked = "most") {
        const images = Object.values(await this.getImages());
        if (images.length === 0) {
            return [];
        }

        let topImages = [];
        if (imageLimit > images.length) {
            return false;
        }
        topImages = images.slice().sort((a, b) => {
            const likedOrder = {
                "least": (a.likes.length - b.likes.length),
                "most": (b.likes.length - a.likes.length)
            };
            return likedOrder[liked];
        }).slice(0, imageLimit);

        return topImages;
    }

    /**
     * Get images by createdAt Order
     *
     * @param {number} imageLimit The number of images to return.
     * @param {string} orderBy The order of createdAt to return.
     * @return {promise} ordered images.
     */
    async getImagesByCreatedAt(imageLimit = 5, orderBy = "desc") {
        const images = Object.values(await this.getImages());
        if (images.length === 0) {
            return [];
        }

        let topImages = [];
        if (imageLimit > images.length) {
            return false;
        }
        topImages = images.slice().sort((a, b) => {
            const createdAtOrder = {
                "asc": (a.created_at - b.created_at),
                "desc": (b.created_at - a.created_at)
            };
            return createdAtOrder[orderBy];
        }).slice(0, imageLimit);

        return topImages;
    }

    /**
     * Get ImageKit Url
     *
     * @param {string} path The image path
     * @param {object} dimensions The image width and height dimensions
     * @return {string} imageKitUrl.
     */
    getImageKitUrl(path, dimensions, clip = false) {
        return getImageKitUrl(path, dimensions, clip);
    }

    /**
     * Get images collections
     *
     * @return {array} collection images.
     */

    async getImageCollections() {
        const images = Object.values(await this.getImages());
        const collections = new Set(images.map(image => image.collection));

        collections.delete(undefined);
        collections.delete("");

        return [...collections];
    }

    /**
     * Get images by collection
     *
     * @param {string} collection The image collection.
     * @return {array} collection images.
     */
    async getImagesByCollection(collection) {
        if (collection) {
            const images = Object.values(await this.getImages());
            return images.filter(image => image.collection === collection);
        }
        return [];
    }

    async getTopImages(imageLimit = 24, liked = "most") {
      const oldImages = Object.values(await this.getImagesByCreatedAt(100, "asc"));
      const newImages = Object.values(await this.getImagesByCreatedAt(100, "desc"));
      const imageRatio = {
        "old": 0.3 * imageLimit,
        "new": 0.7 * imageLimit
      };
      const selectedOldImages = (oldImages.length > 0 ? getRandomImages(oldImages, imageRatio["old"]) : []);
      const selectedNewImages = (newImages.length > 0 ? getRandomImages(newImages, imageRatio["new"]) : []);

      return selectedNewImages.concat(selectedOldImages);
    }
}

export default new ImageService;
