<template>
  <div class="home">
    <section-hero>
      <search-bar
          @handleSubmit="handleSearch"
      />
    </section-hero>

    <section class="section-featured bg-light">
      <page-header
          title="Featured collections"
          theme="light"
          description="Check out some of our featured collections for this month."
      />

      <b-container fluid="xl">
        <featured-collections :prop-collections="collections" :prop-is-admin="false"/>
      </b-container>

    </section>

    <section class="section-featured bg-white">
      <page-header
          title="Top images"
          theme="light"
          description="Check out some of our top images."
      />

      <b-container fluid="xl">

        <gallery :images="topImages" @updateCartCount="updateCartCount"/>

        <button
            @click="navigateToPage('Images')"
            class="browse-btn btn btn-lg btn-dark"
        >
          <span class="me-2">Browse all images</span>
          <b-icon-arrow-right-circle-fill class="d-inline"/>
        </button>

      </b-container>
    </section>

    <section-about/>
  </div>
</template>
<script>
import ImageService from "@/services/image/ImageService";
import PageHeader from "@/components/PageHeader";
import router from "@/router";
import SearchBar from "@/components/SearchBar";
import {getRandomImages} from "@/utils/RandomUtil";
import Gallery from "@/components/Gallery";
import SectionHero from "@/components/sections/SectionHero";
import SectionAbout from "@/components/sections/SectionAbout";
import FeaturedCollections from "@/components/FeaturedCollections.vue";

export default {
  name: "Home",
  components: {
    FeaturedCollections,
    SectionAbout,
    SectionHero,
    SearchBar,
    PageHeader,
    Gallery
  },
  props: {
    imageId: {
      type: String,
    }
  },
  data() {
    return {
      images: [],
      topImages: [],
      ImageService: ImageService,
      defaultBrowseImage: "",
      collections: []
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.images = await this.getImages();
    await this.getTopImages(40);
    this.setDefaultBrowseImage();
    this.collections = await this.$store.dispatch("collections/getCollections", {published: true})
  },
  methods: {
    updateCartCount(cartItems) {
      this.$emit("updateCartCount", cartItems)
    },
    handleSearch(queryString) {
      this.$router.push({
        name: "Images",
        query: {
          search: queryString
        }
      })
    },
    setDefaultBrowseImage() {
      this.defaultBrowseImage = this.images[100]
    },
    gotoImages(image) {
      this.$router.push({name: "Browse", params: {"imageId": image.asset_id}})
    },
    async getImages() {
      let images = await ImageService.getImages();
      return images != null ? images : [];
    },
    getImageIndex(images, imageId) {
      return images.findIndex(image => image.asset_id === imageId);
    },

    async getTopImages(imageLimit) {
      this.shuffleArray(this.images); // Randomize the array
      this.topImages = this.images.slice(0, imageLimit);
      return this.images.splice(0, imageLimit);
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
      }
    },
    navigateToPage(page) {
      router.push({name: page});
    },
  }
}
</script>
<style lang="scss" scoped>
.section-featured {
  padding: 0 0 3rem;
}

.gallery-loading {
  min-height: 100vh;
}

.browse-btn {
  display: block;
  width: auto;
  margin: 5rem auto;
  padding: 1rem 2rem;

  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-out;
  }
}
</style>
