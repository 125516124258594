import {child, get, ref, set, update} from "firebase/database";
import {database} from "../../../firebaseDatabaseConfig";
import ImageModel from "./ImageModel";

/**
 * documentUrl is database url that is set to `image`
 * @type {string}
 * @public
 */
const DOCUMENT_URL = "photos";

/**
 * @description Data Access Object for Image
 * @class
 * @public
 */

class ImageDao {

    constructor() {
        this.documentUrl = `${process.env.VUE_APP_DEPLOYMENT_ENV}/${DOCUMENT_URL}`;
    }

    /**
     * Add Image.
     *
     * @param {string} imageIndex  image index
     * @param {object} image  image to add
     * @return {object} added image
     *
     *
     */
    addImage(imageIndex, image) {
        const imageModel = new ImageModel(image.asset_id, image.title, image.public_id, image.url,
            image.format, image.creator, image.collection);
        const imageUrl = `${this.documentUrl}/${imageIndex}`;
        const mergedImage = {...imageModel, ...image};
        return set(ref(database, imageUrl), mergedImage);
    }

    /**
     * Update image
     *
     * @param {string} imageId The imageId to update.
     * @param {object} image The image data to update.
     * @return {object} updated image.
     */
    updateImage(imageId, image) {
        const imageUrl = `${this.documentUrl}/${imageId}`;

        const updates = {};
        updates[imageUrl] = {...image};
        updates['updated_at'] = new Date().toISOString();
        return update(ref(database), updates);
    }

    /**
     * Delete image
     *
     * @param {string} imageId The imageId to delete.
     * @return {object} deleted image.
     */
    deleteImage(imageId) {
        return this.updateImage(imageId, null);
    }

    /**
     * Get image
     *
     * @param {string} imageId The imageId to update.
     * @return {promise} selected image.
     */
    getImage(imageId) {
        const dbRef = ref(database);
        const imageUrl = `${this.documentUrl}/${imageId}`;

        return get(child(dbRef, imageUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return {};
            }
        }).catch((error) => {
            throw new Error(error);
        });
    }

    /**
     * Get all images
     *
     * @return {promise} all images.
     */
    getImages() {
        const dbRef = ref(database);
        const imageUrl = `${this.documentUrl}`;

        return get(child(dbRef, imageUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return [];
            }
        }).catch((error) => {
            throw new Error(error);
        });
    }
}

export default new ImageDao;
