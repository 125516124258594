<template>
  <card class="slip" :class="theme">
    <div class="row">
      <slot />
    </div>
  </card>
</template>
<script>

import Card from "@/components/Card";
export default {
  name: "Slip",
  components: {Card},
  props: {
    theme: {
      type: String
    }
  },
}

</script>
<style lang="scss" scoped>
.slip {
  font-size: .875rem;
  transition: all 200ms ease-out;
  border-top-left-radius: 0;
  background-color: white;
  background-image: linear-gradient(rgba(0,0,0,0) 0, rgba(0,0,0,.05) 100%);

  &:hover {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 10%);
  }

  .row {
    margin: 0 !important;
  }

  @media (min-width: 992px) {
    font-size: 1rem;
  }

  &.download {
    padding: 0;

    .row {
      padding: 0;
    }

    ::v-deep {
      .card-body {
        padding: 0;
      }

      div[class^="col"] {
        padding: 20px;
        align-self: center;

        &:last-child {
          display: block;
          padding: 0;

          @media (min-width: 576px) {
            align-self: auto;
            width: 15%;
            flex: 0 0 auto;
          }

          .btn {
            height: 100%;
            width: 100%;
            font-size: .875em;
            padding: .5rem 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            @media (min-width: 576px) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }

            @media (min-width: 768px) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          span {
            display: block;

            svg {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}
</style>
