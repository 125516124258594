<template>
    <div class="modal-vue">
        <div class="overlay" v-if="showModal" @click="showModal = false"></div>
        <div class="modal" v-if="showModal">
                <b-icon icon="x-circle" scale="1" variant="danger" class="close" @click="showModal = false"></b-icon>
                <iframe width="804px" height="574px" style="opacity: 1; overflow-y: hidden; box-shadow: none; border-width: 0px !important; width: 800px; height: 568px; max-height: 100vh; max-width: 100vw;" src="https://a.plerdy.com/click/iframe?user_id=46320&form_id=7159"></iframe>
        </div>
    </div>
</template>

<script>
import {initializeApp} from "firebase/app";
import {getDatabase,ref,push} from "firebase/database";
import { ref as ref2 } from "vue";

import 'firebase/database';
export default {
    data() {
        return {
            name: '',
            email: '',
            showModal: true
            
        }
    },
     
}

</script>


<style scoped >
.modal-vue .overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal {
    margin-top: 75px !important;
    position: relative;
    width: 64%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
}

.modal-vue .close {
    position: absolute;
    top: 15px;
    right: 10px;
}

.modal-vue .close:hover{
    cursor: pointer;
}


</style>