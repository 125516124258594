<template>
  <div class="mt-3 text-start border rounded p-4">
    <div class="mt-3">
      <div class="form-label mb-2" id="imageTitle">Title</div>
      <input type="text" class="form-control" placeholder="Title" aria-label="Username"
             aria-describedby="basic-addon1" v-model="title">
    </div>
    <div class="mt-3">
      <span class="form-label py-2">Tags</span>
      <multi-select data-set="tags" :selected-options="tags" @handleInput="handleInput"/>
    </div>
    <div class="mt-3">
      <span class="form-label mb-1" id="imageDescription">Description </span>
      <textarea class="form-control" aria-label="With textarea" placeholder="Description" rows="5"
                v-model="description"></textarea>
    </div>
    <div class="mt-3">
      <div class="form-label mb-2" id="imageCreator">Creator</div>
      <input type="text" class="form-control" placeholder="Creator" aria-label="Creator"
             aria-describedby="basic-addon1" v-model="creator">
    </div>
    <div class="mt-3">
      <div class="form-label mb-2" id="imageCollection">Collection</div>
      <DataList data-set="collection" :data-items="collections" :item="collection" @handleInput="handleInput"/>
    </div>
    <div class="form-check mt-3">
      <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked v-model="access_mode">
      <label class="form-check-label" for="defaultCheck1">
        This is image is free
      </label>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <button class="btn btn-outline-danger" @click="deleteImageDetails">
        <em class="bi bi-trash-fill"></em>
        Delete
      </button>
      <button class="btn btn-outline-info" @click="editImageDetails">
        <em class="bi bi-save-fill"></em>
        {{ formBottomText }}
      </button>
    </div>
  </div>
</template>
<script>
import MultiSelect from "@/components/MultiSelect";
import DataList from "@/components/DataList";

export default {
  name: "AdminEditImageForm",
  components: {
    DataList,
    MultiSelect
  },
  props: {
    imageIndex: {
      type: Number
    },
    formBottomText: {
      type: String
    },
    image: {
      type: Object
    },
    collections: {
      type: Array
    }
  },
  data() {
    return {
      title: this.image.title || "",
      tags: (typeof (this.image.tags) === "string" ? this.image.tags.split(",").filter(n => n) : this.image.tags),
      description: this.image.description || "",
      creator: this.image.creator || "",
      collection: this.image.collection || "",
      access_mode: this.image.access_mode || false
    }
  },
  methods: {
    validateEditedImageDetails() {
      if (this.title === "" || this.tags.length === 0 || this.description === "" || this.collection === "") {
        return false
      }
      return true
    },
    editImageDetails() {
      if (this.validateEditedImageDetails()) {
        this.$emit("saveImageInfo", {
          title: this.title,
          tags: this.tags,
          description: this.description,
          creator: this.creator,
          collection: this.collection,
          access_mode: this.access_mode,
          updated_by: this.$store.state.user.email,
          updated_at: new Date()
        });
      } else {
        this.$toast.error("Please fill [Title, Tags, Description, Collection] fields");
      }

    },
    handleInput(input) {
      this[input.key] = input.values;
    },
    deleteImageDetails() {
      const proceed = confirm("Delete! Are you sure?");
      if (proceed) {
        this.$emit("removeImageInfo");
      }
    }
  }
}
</script>
<style scoped>
</style>
