<template>
  <div class="page container-xl">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-8 d-flex justify-content-between align-items-center">
        <button class="btn btn-outline" @click="moveToImage('backward', index)" :disabled="index === 0">
          <em class="bi bi-arrow-left-square-fill image-action-btn"></em>
        </button>
        <div class="d-flex flex-column align-items-center">
          <p>
            {{ index + 1 }} of {{ images.length }}
          </p>
          <product-viewer :image="image" v-if="image"/>
        </div>
        <button class="btn btn-outline" @click="moveToImage('forward',index )" :disabled="index === images.length">
          <em class="bi bi-arrow-right-square-fill image-action-btn"></em>
        </button>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <admin-edit-image-form
            v-if="image"
            form-bottom-text="Save"
            :image-index="index"
            :image="image"
            :key="index"
            :collections="collections"
            @saveImageInfo="updateImageInformationFirestore"
            @removeImageInfo="removeImageInformationFirestore"
        />
      </div>
    </div>
  </div>
</template>
<!--suppress JSUnresolvedFunction -->
<script>
import ProductAddCart from "@/components/ProductAddCart.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import ProductLicense from "@/components/ProductLicense.vue";
import ProductTag from "@/components/ProductTag.vue";
import ProductViewer from "@/components/ProductViewer.vue";
import AdminEditImageForm from "@/components/AdminEditImageForm";
import ImageService from "@/services/image/ImageService";
import {deleteObject, ref} from "firebase/storage";
import {storage} from "../../firebaseConfig";

export default {
  name: 'ImagesEditingAdmin',
  components: {
    AdminEditImageForm,
    ProductAddCart,
    ProductInfo,
    ProductLicense,
    ProductTag,
    ProductViewer
  },
  async created() {
    window.scrollTo(0, 0);
    await this.setImage();
    await this.getImages()
    this.index = await this.getImageIndex();
    this.collections = await this.getImageCollections();
  },
  data() {
    return {
      images: [],
      imageId: this.getImageId(),
      index: null,
      image: null,
      collections: []
    }
  },
  methods: {
    saveLastEditedImageIndex(index) {
      window.localStorage.setItem("lastEditedImageIndex", index);
    },
    async getImages() {
      this.images = await ImageService.getImages();
    },
    getImageId() {
      return this.$route.params.imageId;
    },
    getImageIndex() {
      return this.images.findIndex(image => image.asset_id === this.$route.params.imageId);
    },
    async setImage() {
      this.image = await ImageService.getImage(this.$route.params.imageId);
    },
    async getImageCollections() {
      return ImageService.getImageCollections();
    },
    moveToImage(direction, currentIndex) {
      if (direction === 'backward') {
        this.index--;
      }
      if (direction === 'forward') {
        this.index++;
      }
      this.saveLastEditedImageIndex(currentIndex);
      this.image = this.images[this.index];
      this.$router.push({name: 'ImagesEditingAdmin', params: {imageId: this.image.asset_id}});
    },
    async updateImageInformationFirestore(data, isDelete = false) {
      const imageId = this.image.asset_id;
      if (isDelete) {
        await ImageService.deleteImage(imageId);
        this.$toast.error("Image has been successfully removed!");
      }
      if (!isDelete) {
        await ImageService.updateImage(imageId, {...this.image, ...data});
        this.$toast.success("Image has been successfully updated!");
      }
      await this.getImages();
    },
    async removeImageInformationFirestore() {
      const filePath = this.image.public_id;
      const isDeleted = await this.deleteFile(filePath);
      if (!isDeleted) {
        this.$toast.warning("Image not found!");
      }
      await this.updateImageInformationFirestore(null, true);
    },
    async deleteFile(filePath) {
      const fileRef = ref(storage, filePath);
      return deleteObject(fileRef).then(() => {
        return true;
      }).catch((e) => {
        console.error(e);
        return false;
      });
    }
  }
}
</script>
<style scoped>
.image-action-btn {
  font-size: 3rem;
}
</style>
