<template>
  <label>
    <input
        @click="selectLicenseHandler(license)"
        type="radio"
        name="license"
    />
    <div class="box">
      <b-row>
        <b-col cols="3" class="radio-col1">
          <svg class="radio-svg" width="44" height="44" viewBox="0 0 20 20">
            <circle cx="10" cy="10" r="9"></circle>
            <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" class="inner"></path>
            <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" class="outer"></path>
          </svg>
        </b-col>
        <b-col cols="5" class="radio-col2">
          <p>{{ license.name }}</p>
          <p class="text-sm">{{ license.description }}</p>
        </b-col>
        <b-col cols="4" class="radio-col3">
          <p>${{ license.price }}</p>
        </b-col>
      </b-row>
    </div>
  </label>
</template>
<script>
export default {
  name: "ProductLicense",
  props: {
    license: {
      type: Object,
    }
  },
  methods: {
    selectLicenseHandler: function (licenseItem) {
      this.$emit('setCartItem', licenseItem);
    }
  }
}
</script>
<style lang="scss" scoped>
label {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 .5rem;
}

.radio-svg {
  width: 44px;
  height: 44px;
  fill: none;
  vertical-align: middle;

  circle {
    stroke-width: 2;
    stroke: #C8CCD4;
  }

  path {
    stroke: #198754;

    &.inner {
      stroke-width: 6;
      stroke-dasharray: 19;
      stroke-dashoffset: 19;
    }

    &.outer {
      stroke-width: 2;
      stroke-dasharray: 57;
      stroke-dashoffset: 57;
    }
  }
}

input[type="radio"] {
  display: none;

  &:checked {
    + .box {
      background-color: #d4e9df;
      border-color: #198754;
      color: #198754;

      span {
        color: white;
        transform: translateY(70px);

        &:before {
          transform: translateY(0px);
          opacity: 1;
        }
      }

      .radio-svg {
        path {
          transition: all 250ms ease;

          &.inner {
            stroke-dashoffset: 38;
            transition-delay: 150ms;
          }

          &.outer {
            stroke-dashoffset: 0;
          }
        }
      }
    }
  }
}

.box {
  display: block;
  width: 100%;
  background-color: #e9e9e9;
  border-radius: 0.25rem;
  transition: all 250ms ease;
  will-change: transition;
  text-align: left;
  cursor: pointer;
  position: relative;
  border: 1px solid #bababa;

  &:active {
    transform: translateY(5px);
  }

  span {
    position: absolute;
    transform: translate(0, 60px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 1.5em;
    user-select: none;
  }
}

.row {
  margin: 0;
}

.radio-col1 {
  position: relative;
  text-align: center;
  padding-top: .75rem;
  background: rgba(0,0,0,.025);

  @media (min-width: 576px) {
    padding-top: 1rem;
  }
}

.radio-col2 {
  padding: .75rem;

  @media (min-width: 576px) {
    padding: 1rem;
  }
}

.radio-col3 {
  padding: .75rem;
  background: rgba(0,0,0,.05);

  @media (min-width: 576px) {
    padding: 1rem;
  }
}

.image-svg {
  margin: .25rem;
  opacity: .2;
}

p {
  font-size: .9em;
  font-weight: 500;
  margin: 0;

  @media (min-width: 576px) {
    font-size: 1rem;
  }
}

.text-sm {
  font-size: .75em;
}

.text-md {
  @media (min-width: 576px) {
    font-size: 1.1em;
  }
}

.table td {
  text-align: center;
}
</style>
