<template>
  <div class="page container-xl">
    <div class="row m-5 d-flex justify-content-center text-start align-items-center">
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-xs-12">
        <div class="form mb-5">
          <div
              class="alert alert-primary"
              role="alert"
              v-if="routeName==='SignIn'"
          >
            Please sign in to your account.
          </div>
          <div
              class="alert alert-warning"
              role="alert"
              v-if="routeName==='SignUp'"
          >
            Please create an account.
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Accounts",
  mounted() {
    this.$store.commit("setPathName", "Login")
    this.$emit("reloadNavigation")
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
.form-row {
  margin: 0 auto 1rem;

  .form-check {
    display: block;
    min-height: 1.5rem;
    padding: 1em 1em 1em 2.2em;
    font-size: .875em;
    background: #f1efef;
  }
}

.form-actions {
  margin: 2rem 0;

  .btn {
    display: block;
    width: auto;
    margin: 0 0 1rem;
  }

  .btn-link {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}
</style>
