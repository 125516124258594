<template>
  <div class="account-navigation">
    <div class="account-navigation-header">
      <h5 class="visually-hidden">Account settings</h5>

      <div class="panel-flipper">
        <div
            v-if="isLoggedIn"
            class="panel-flipper-option"
        >
          <button
              class="panel-flipper-cta"
              :class="{'active': isActive('my-account')}"
              @click.stop="setActive('my-account')"
          >
            <div class="panel-flipper-text">MY ACCOUNT</div>
          </button>
        </div>

        <div
            v-if="!isLoggedIn"
            class="panel-flipper-option"
        >
          <button
              class="panel-flipper-cta"
              :class="{'active': isActive('signin') || isActive('reset-password')}"
              @click.stop="setActive('signin')"
          >
            <div class="panel-flipper-text">SIGN IN</div>
          </button>
        </div>

        <div
            v-if="!isLoggedIn"
            class="panel-flipper-option"
        >
          <button
              class="panel-flipper-cta"
              :class="{'active': isActive('register')}"
              @click.stop="setActive('register')"
          >
            <div class="panel-flipper-text">REGISTER</div>
          </button>
        </div>
      </div>

      <button
          @click="closeMenu"
          type="button"
          class="btn-close text-reset"
          aria-label="Close"
      />
    </div>
    <div class="account-navigation-body">
      <div class="panel-wrapper">
        <div
            v-if="isLoggedIn"
            class="panel"
            :class="{'active': isActive('my-account')}"
        >

          <ul class="panel-list">
            <li
                @click.prevent="navigateToPage('Orders')"
                :class="{'active': this.$route.name === 'Orders'}"
            >
              <i class="bi bi-box-arrow-up-left"></i> My Orders
            </li>
            <li
                @click.prevent="navigateToPage('ChangePassword')"
                :class="{'active': this.$route.name === 'ChangePassword'}"
            >
              <i class="bi bi-box-arrow-up-left"></i> Change Password
            </li>
            <li
                v-if="user.permissions.CAN_VIEW_ADMIN && isLoggedIn && isAdmin"
                @click.prevent="navigateToPage('ImagesAdmin')"
                :class="{'active': this.$route.name === 'ImagesAdmin'}"
            >
              <i class="bi bi-box-arrow-up-left"></i> Manage Images
            </li>
            <li
                v-if="user.permissions.CAN_VIEW_ADMIN && isLoggedIn && isAdmin"
                @click.prevent="navigateToPage('CollectionsAdmin')"
                :class="{'active': this.$route.name === 'CollectionsAdmin'}"
            >
              <i class="bi bi-box-arrow-up-left"></i> Manage Collections
            </li>
            <li
                v-if="user.permissions.CAN_UPLOAD_IMAGES && isLoggedIn && isAdmin"
                @click.prevent="navigateToPage('Upload')"
                :class="{'active': this.$route.name === 'Upload'}"
            >
              <i class="bi bi-box-arrow-up-left"></i> Upload Images
            </li>
            <li
                @click.prevent="logout"
                class="list-item-warning"
            >
              Log Out
            </li>

          </ul>

        </div>
        <div
            v-if="!isLoggedIn"
            class="panel"
            :class="{'active': isActive('signin') || isActive('reset-password')}"
        >

          <form-sign-in
              v-if="isActive('signin')"
          />

          <reset-password
              v-if="isActive('reset-password')"
          />

        </div>
        <div
            v-if="!isLoggedIn"
            class="panel"
            :class="{'active': isActive('register')}"
        >

          <form-sign-up />

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
import FormSignIn from "@/components/auth/SignInForm";
import FormSignUp from "@/components/auth/SignUpForm";
import defaultUserPermissions from "@/services/accounts/PermissionsConfig";
import ResetPassword from "@/components/auth/ResetPassword";

export default {
  name: "AccountNavigation",
  components: {
    ResetPassword,
    FormSignUp,
    FormSignIn
  },
  data() {
    return {
      showMenu: false
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.setActive('my-account');
    } else {
      this.setActive('signin');
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
    isAdmin() {
      return this.$store.state.user.role === 'admin'
    },
    user() {
      let user = {
        permissions: defaultUserPermissions
      }
      if (this.$store.state.isLoggedIn) {
        return this.$store.state.user
      }
      return user
    },
    getActiveMenuView() {
      return this.$store.state.activeMenuView
    },
  },
  methods: {
    isActive(menuItem) {
      return this.getActiveMenuView === menuItem;
    },
    setActive(menuItem) {
      this.$store.commit("setActiveMenuView", menuItem)
    },
    closeMenu() {
      this.$store.commit('closeMenu')
    },
    navigateToPage(page) {
      this.closeMenu();
      router.push({name: page});
    },
    logout() {
      this.closeMenu();
      this.setActive('signin');
      this.$toast.warning("You have been logged out");
      this.$store.commit("setUserDetails", {})
      this.$store.commit("setLoggedInStatus", false)
      this.$router.push({name: 'Home'});
    },
  }
}
</script>
<style lang="scss" scoped>
.account-navigation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    margin: 0;
  }
}

.account-navigation-body {
  padding: 0;
  overflow: visible;
}

.panel-flipper {
  display: flex;
  justify-content: center;

  .panel-flipper-option {
    &:not(:first-child) {
      margin-left: 3.75em;
    }
  }

  .panel-flipper-cta {
    position: relative;
    background: none;
    border: none;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    outline: none;
    white-space: nowrap;

    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 7px;
      background-color: #fec70a;
      opacity: 0;
    }

    &.active {
      &::after {
        opacity: 1;
      }
    }
  }
}

.panel {
  display: none;
  text-align: left;
  margin: 3rem 0 0;

  &.active {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 1rem;
      cursor: pointer;
      background: rgba(0,0,0,0.1);
      padding: 1em;

      &:hover,
      &:active {
        background: rgba(0,0,0,0.05);
        transform: scale(1.025);
        transition: all 250ms ease-in-out;
      }

      .bi {
        position: relative;
        top: -.02em;
        width: 1em;
        height: 1em;
        margin: 0 .25em 0;
      }

      &.active {
        background: #d6eef9;
      }

      &.list-item-warning {
        background: #fec70a;
        margin-top: 2rem;

        &:hover,
        &:active {
          background: #f37033;
        }
      }
    }
  }

  button,
  .btn {
    box-shadow: none;
  }

  .greeting {
    padding: 0 0 1rem;
  }
}


</style>
