<template>
  <div class="product-add-to-cart">
    <h5>${{ this.cartItem.license.price }}</h5>
    <div class="d-flex flex-row justify-content-between">
      <div class="btn btn-md btn-info" @click="navigateBack">Back</div>
      <div class="btn btn-md btn-primary" @click="addCart">Add to cart</div>
    </div>
  </div>
</template>
<script>
import Cart from "@/assets/cart.js";

export default {
  name: "ProductAddCart",
  props: {
    cartItem: {
      type: Object,
    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1);
    },
    addCart() {
      const isSelected = this.cartItem.license.name !== "";
      if (isSelected) {
        let itemSelected = this.cartItem;
        let itemMatch = Cart.getItem(itemSelected);
        if (itemMatch) {
          this.$toast.error('Item already in Cart!');
        }
        if (!itemMatch) {
          Cart.addItem(itemSelected);
          this.$emit('updateCartCount', Cart.countItems());
          this.$toast.success('Item added to Cart!');
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.product-add-to-cart {
  border-top: 5px solid #fdc60d;
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin: 1rem 0;
  background: #f8f8f8;
  border-bottom-right-radius: 1em;
}
</style>
