<template>
  <div>
    <svg version="1.1" id="logo" :class="[textColor]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 151.9 42.6" style="enable-background:new 0 0 151.9 42.6;" xml:space="preserve">

      <g id="text">
	<g>
		<path d="M53.8,27.1c-0.2,0-0.4-0.1-0.6-0.2C53,26.7,53,26.5,53,26.3v-18h-6.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.3-0.2-0.6v-3
			c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2h17.6c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v3c0,0.2-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.4,0.2-0.6,0.2h-6.1v18c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H53.8z"/>
    <path d="M67.2,27.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V4.1c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2h3.6
			c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v7.5c0.6-0.7,1.3-1.3,2.2-1.7c0.9-0.4,1.9-0.6,3.2-0.6c1.3,0,2.5,0.3,3.5,0.9
			c1,0.6,1.8,1.5,2.4,2.6c0.6,1.1,0.9,2.5,0.9,4.1v9.4c0,0.2-0.1,0.4-0.2,0.6s-0.4,0.2-0.6,0.2h-3.7c-0.2,0-0.4-0.1-0.6-0.2
			c-0.2-0.2-0.2-0.4-0.2-0.6v-9.2c0-1.2-0.3-2.1-0.9-2.8c-0.6-0.7-1.4-1-2.5-1c-1.1,0-1.9,0.3-2.5,1c-0.6,0.7-1,1.6-1,2.8v9.2
			c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2H67.2z"/>
    <path d="M94.6,27.4c-1.9,0-3.4-0.3-4.7-0.9c-1.3-0.6-2.2-1.5-2.9-2.6c-0.7-1.1-1-2.5-1.1-4c0-0.4,0-1,0-1.5s0-1.1,0-1.5
			c0.1-1.5,0.4-2.9,1.1-4c0.7-1.1,1.7-2,2.9-2.6c1.3-0.6,2.8-0.9,4.6-0.9c1.8,0,3.3,0.3,4.5,0.9c1.3,0.6,2.2,1.5,2.9,2.6
			c0.7,1.1,1.1,2.5,1.1,4c0,0.4,0.1,0.9,0.1,1.5s0,1.1-0.1,1.5c-0.1,1.5-0.4,2.9-1.1,4c-0.7,1.1-1.6,2-2.9,2.6
			C98,27.1,96.5,27.4,94.6,27.4z M94.6,23.7c1.1,0,1.9-0.3,2.5-1c0.6-0.7,0.9-1.7,0.9-3c0-0.3,0-0.8,0-1.3c0-0.6,0-1,0-1.3
			c0-1.3-0.3-2.3-0.9-3c-0.6-0.7-1.4-1-2.5-1s-1.9,0.3-2.5,1c-0.6,0.7-0.9,1.7-0.9,3c0,0.3,0,0.8,0,1.3c0,0.6,0,1,0,1.3
			c0,1.3,0.4,2.3,0.9,3C92.7,23.4,93.5,23.7,94.6,23.7z"/>
    <path d="M106.4,27.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V10.5c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2h3.1
			c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.6v1.1c0.4-0.6,1.1-1.2,1.9-1.6c0.8-0.4,1.7-0.7,2.8-0.7c2.6,0,4.4,0.9,5.3,3
			c0.5-0.9,1.3-1.6,2.2-2.1c1-0.5,2-0.8,3.2-0.8s2.2,0.3,3.2,0.8s1.7,1.3,2.2,2.4c0.5,1.1,0.8,2.5,0.8,4.2v9.6
			c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2h-3.3c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.4-0.2-0.6V17c0-0.9-0.1-1.6-0.4-2.2
			c-0.3-0.5-0.6-0.9-1.1-1.1c-0.4-0.2-0.9-0.3-1.5-0.3c-0.5,0-0.9,0.1-1.4,0.3c-0.4,0.2-0.8,0.6-1.1,1.1c-0.3,0.5-0.4,1.2-0.4,2.2
			v9.3c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-3.3c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V17
			c0-0.9-0.1-1.6-0.4-2.2c-0.3-0.5-0.6-0.9-1.1-1.1c-0.4-0.2-0.9-0.3-1.4-0.3c-0.5,0-0.9,0.1-1.4,0.4s-0.8,0.6-1.1,1.1
			c-0.3,0.5-0.4,1.2-0.4,2.1v9.3c0,0.2-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2H106.4z"/>
    <path d="M142.7,27.4c-2.6,0-4.6-0.7-6.1-2.1c-1.5-1.4-2.3-3.5-2.4-6.2c0-0.2,0-0.4,0-0.7s0-0.5,0-0.7c0.1-1.7,0.5-3.2,1.2-4.4
			c0.7-1.2,1.7-2.2,2.9-2.9c1.3-0.7,2.7-1,4.4-1c1.9,0,3.5,0.4,4.7,1.2c1.3,0.8,2.2,1.8,2.9,3.2c0.6,1.3,1,2.9,1,4.6V19
			c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-11c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.7,0.2,1.4,0.4,2
			c0.3,0.6,0.6,1.1,1.1,1.4c0.5,0.4,1.1,0.5,1.7,0.5c0.6,0,1-0.1,1.4-0.2c0.4-0.2,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.4,0.5-0.6
			c0.2-0.2,0.4-0.4,0.5-0.4c0.1-0.1,0.3-0.1,0.6-0.1h3.5c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.4-0.2,0.8-0.6,1.3
			s-0.9,1-1.5,1.5c-0.7,0.5-1.5,0.9-2.5,1.3C145.2,27.3,144,27.4,142.7,27.4z M139.4,16.7h6.6v-0.1c0-0.8-0.1-1.5-0.4-2.1
			c-0.3-0.6-0.6-1.1-1.1-1.4s-1.1-0.5-1.8-0.5s-1.3,0.2-1.8,0.5c-0.5,0.3-0.9,0.8-1.1,1.4C139.5,15.2,139.4,15.9,139.4,16.7
			L139.4,16.7z"/>
	</g>
        <g>
		<path d="M54.1,40.8c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2v-8.4c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h0.2
			c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v8.4c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H54.1z"/>
          <path d="M67.3,40.8c-0.1,0-0.2,0-0.2-0.1C67,40.6,67,40.6,67,40.5v-8.4c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h0.3
			c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1l2.9,5.7l2.9-5.7c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.1h0.3c0.1,0,0.2,0,0.2,0.1
			c0.1,0.1,0.1,0.1,0.1,0.2v8.4c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H74c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2
			v-6.9l-2.4,4.8c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.3c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.2l-2.4-4.8v6.9
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H67.3z"/>
          <path d="M86.3,40.8c-0.1,0-0.1,0-0.2-0.1C86,40.6,86,40.6,86,40.5c0,0,0-0.1,0-0.1l3.2-8.3c0-0.1,0.1-0.1,0.1-0.2s0.1-0.1,0.2-0.1
			h0.4c0.1,0,0.2,0,0.2,0.1s0.1,0.1,0.1,0.2l3.2,8.3c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-0.3
			c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1l-0.8-2.1h-4.5l-0.8,2.1c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.2,0.1H86.3z M87.9,37.7h3.9l-2-5
			L87.9,37.7z"/>
          <path d="M108.1,40.9c-0.7,0-1.4-0.1-1.9-0.4c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1.1-0.4-1.8c0-0.4,0-0.8,0-1.2c0-0.4,0-0.8,0-1.2
			c0-0.7,0.2-1.3,0.4-1.8c0.3-0.5,0.6-0.9,1.1-1.2c0.5-0.3,1.1-0.4,1.8-0.4c0.6,0,1.1,0.1,1.5,0.3c0.4,0.2,0.8,0.4,1,0.7
			c0.3,0.3,0.5,0.5,0.6,0.8c0.1,0.3,0.2,0.5,0.2,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.3c-0.1,0-0.1,0-0.2-0.1
			c0,0-0.1-0.1-0.1-0.2c-0.1-0.3-0.2-0.5-0.4-0.8c-0.2-0.2-0.5-0.4-0.8-0.6s-0.8-0.2-1.3-0.2c-0.8,0-1.4,0.2-1.9,0.6
			s-0.7,1.1-0.8,2.1c0,0.8,0,1.5,0,2.3c0,1,0.3,1.6,0.8,2.1c0.5,0.4,1.1,0.6,1.9,0.6c0.5,0,1-0.1,1.4-0.3c0.4-0.2,0.7-0.5,0.9-0.9
			c0.2-0.4,0.3-0.9,0.3-1.5v-0.7h-2.2c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2v-0.2c0-0.1,0-0.2,0.1-0.2
			c0.1-0.1,0.1-0.1,0.2-0.1h2.7c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v1.1c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.5-0.7,0.9-1.2,1.2
			C109.5,40.7,108.9,40.9,108.1,40.9z"/>
          <path d="M123.8,40.8c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2v-8.4c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h5
			c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-4.5v3.3h4.2
			c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2v0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-4.2V40h4.6c0.1,0,0.2,0,0.2,0.1
			c0.1,0.1,0.1,0.1,0.1,0.2v0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H123.8z"/>
          <path d="M143.8,40.9c-0.7,0-1.3-0.1-1.8-0.3c-0.5-0.2-0.9-0.5-1.1-0.9c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.1,0-0.1,0.1-0.2
			c0.1-0.1,0.1-0.1,0.2-0.1h0.3c0.1,0,0.2,0,0.2,0.1s0.1,0.1,0.1,0.2c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.4,0.8,0.6
			c0.4,0.2,0.8,0.2,1.4,0.2c0.8,0,1.4-0.2,1.8-0.5c0.4-0.3,0.6-0.7,0.6-1.2c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-0.9-0.6
			c-0.4-0.2-0.9-0.4-1.6-0.6c-0.6-0.2-1.1-0.4-1.5-0.6c-0.4-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.3-0.7-0.3-1.2c0-0.4,0.1-0.8,0.3-1.2
			c0.2-0.3,0.6-0.6,1-0.8c0.4-0.2,1-0.3,1.6-0.3c0.5,0,0.9,0.1,1.3,0.2s0.7,0.3,0.9,0.5c0.2,0.2,0.4,0.5,0.6,0.7s0.2,0.5,0.2,0.8
			c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1h-0.3c0,0-0.1,0-0.2-0.1s-0.1-0.1-0.1-0.2c-0.1-0.4-0.3-0.8-0.7-1.1
			c-0.4-0.3-0.9-0.4-1.5-0.4c-0.6,0-1.1,0.1-1.6,0.4s-0.6,0.6-0.6,1.2c0,0.3,0.1,0.6,0.3,0.8s0.5,0.4,0.8,0.6
			c0.4,0.2,0.9,0.3,1.5,0.5c0.6,0.2,1.2,0.4,1.6,0.6c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1c0,0.5-0.1,1-0.4,1.3s-0.6,0.6-1.1,0.8
			S144.4,40.9,143.8,40.9z"/>
	</g>
</g>
      <g id="icon">
	<circle class="st0" cx="21" cy="21.4" r="20.7"/>
        <g>
		<path d="M17.4,15.2c-0.7,0.2-1.3,0.3-1.8,0.4c-0.6,0.1-1.2,0.2-1.4,1c-0.1,0.2-0.5,0.5-0.8,0.6c-1.4,0.4-2.8,0.8-4.2,1.2
			c-1,0.3-1.9-0.5-1.6-1.5c0.1-0.4,0.5-0.8,0.8-0.9c1.5-0.5,3-0.9,4.6-1.3c0.3-0.1,0.8,0.1,1,0.3c0.4,0.4,0.7,0.4,1.1,0.2
			c0,0,0.1,0,0.1,0c0.7-0.2,1.5-0.2,1.9-0.7c0.3-0.4,0.1-1.3,0.1-2c0-0.3,0-0.6,0.5-0.6c2.9,0,5.8,0,8.7,0c0,0.3,0,0.6,0,0.8
			c0,1.3,0,2.6,0,4c-0.1,1.2,0.2,2.2,0.7,3.2c1.8,3.5,3.5,7.1,5.2,10.7c0.1,0.3,0.5,0.5,0.7,0.8c0.3,0.4,0.6,0.8,0.8,1.2
			c0.3,0.6,0.8,1.4,0.7,1.9c-0.1,0.5-1.1,0.6-1.7,1c-0.6,0.4-0.9,0.3-1.2-0.4c-0.2-0.5-0.5-1-0.7-1.6c-0.1-0.2-0.2-0.4-0.2-0.6
			c0.2-1.4-0.7-2.4-1.2-3.5c-1.6-3.4-3.2-6.7-4.8-10.1c-0.5-1-0.5-1.1-1.8-0.8c0,0.2,0,0.4,0,0.6c0,2.9-0.1,5.7-0.1,8.6
			c0,2.3,0,4.5,0.1,6.8c0,0.2,0,0.4,0.1,0.5c0.9,1.1,0.3,2.4,0.4,3.6c0,0.1-0.3,0.3-0.5,0.3c-0.7,0-1.3,0-2,0
			c-0.4,0-0.5-0.2-0.5-0.5c0-0.8-0.2-1.7,0.1-2.4c0.7-1.6,0.3-3.3,0.5-4.9c0.1-1.2,0-2.4,0-3.6c0-2.7,0-5.4,0-8.1c0-0.3,0-0.6,0-0.8
			c-1.1-0.4-1.4-0.3-1.8,0.6c-2,4.1-3.9,8.2-5.9,12.3c-0.1,0.3-0.1,0.7-0.2,1c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.3-0.3,0.7-0.5,1
			c-0.8,1.6-0.8,1.6-2.4,0.8c-0.3-0.2-0.6-0.3-1.1-0.6c0.7-1.1,0.7-2.5,2-3.3c0.2-0.1,0.3-0.3,0.4-0.5c1.9-4,3.9-7.9,5.7-11.9
			C17.5,17.8,17.3,16.5,17.4,15.2z"/>
          <path d="M17.3,4.8c3.1,0,6.1,0,9.1,0c0,0.1,0.1,0.1,0.1,0.2c0.1,2,0.1,2-1.8,2c-0.7,0-1.3,0-2.1,0c0,0.9,0,1.8,0,2.6
			c0,0.1,0.3,0.4,0.5,0.4c1.1,0,2.2,0,3.3,0c0,0.4,0,0.8,0.1,1.3c-3.1,0-6.1,0-9.2,0c0-0.4,0-0.8-0.1-1.3c1.3,0,2.6,0,3.9,0
			c0-0.9,0-1.8,0-2.7c0-0.1-0.4-0.4-0.5-0.4c-0.9,0-1.8,0-2.7,0c-0.5,0-0.7-0.1-0.6-0.6C17.3,5.9,17.3,5.4,17.3,4.8z"/>
          <path d="M11.4,36.3c-0.3,0.8-0.5,1.6-1.5,1.6c-0.1,0-0.2,0.3-0.4,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2-0.1-0.2-0.4-0.3-0.6
			c-0.1-0.3-0.1-0.5-0.2-0.8c-0.2-0.4,0-1.4,0.4-1.7c0.1-0.1,0.3-0.1,0.5,0C9.8,35.5,10.6,35.9,11.4,36.3z"/>
          <path d="M32.3,36.4c0.9-0.5,1.6-0.8,2.3-1.2c0.4-0.2,0.6-0.1,0.8,0.3c0.2,0.5,0.4,1,0,1.6c-0.1,0.1,0,0.4,0,0.6
			c0,0.5-0.5,0.8-0.9,0.6c-0.4-0.2-0.8-0.3-1-0.5C33,37.4,32.7,36.9,32.3,36.4z"/>
          <path d="M21.9,39.4c0.2,0,0.3,0,0.5,0c0.3,0.1,0.9,0.1,1,0.3c0.1,0.5,0.1,1.1-0.4,1.6c-0.2,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.2-0.4,0.4-0.6,0.4c-0.2,0-0.4-0.3-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.5c-0.4-0.3-0.7-1.3-0.4-1.7c0.1-0.1,0.2-0.2,0.4-0.2
			C21.2,39.4,21.6,39.4,21.9,39.4C21.9,39.4,21.9,39.4,21.9,39.4z"/>
	</g>
</g>
</svg>

  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    textColor: {
      type: String,
      default: 'dark'
    },
  },
}
</script>

<style lang="scss" scoped>
.st0{fill:#FFD437;}

//.st0{fill:#010101;}
//.st1{fill:#2465A9;}
//.st2{fill:#3FA4D7;}
//.st3{fill:#74BFB8;}
//.st4{fill:#FDC70D;}
//.st5{fill:#D92568;}
//.st6{fill:#F37033;}

#logo {
  height: 45px;
  width: auto;

  &.dark {
    #text {
      path {
        fill: #010101;
      }
    }
  }

  &.light {
    #text {
      path {
        fill: #ffffff;
      }
    }
  }
}
</style>