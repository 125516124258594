<template>
  <div
      id="app"
      class="app"
      :style="this.$route.meta.home ? backgroundImage : null"
  >
  <Modal v-if="showModal" />

    <main-navigation :cart-items="cartItems" :key="navigationKey" />
    <router-view
        @updateCartCount="updateCartCount"
        @reloadNavigation="reloadNavigation"
        class="main-content"
    />
    <main-footer />
    <b-sidebar
        v-model="isMenuOpen"
        @hidden="closeMenu"
        id="sidebar-menu"
        title="Account settings"
        no-header
        backdrop-variant="dark"
        backdrop
        right
        shadow
    >
      <account-navigation />
    </b-sidebar>

    <b-sidebar
        v-model="isCartOpen"
        @hidden="closeMenu"
        id="sidebar-cart"
        title="Cart"
        no-header
        backdrop-variant="dark"
        backdrop
        right
        shadow
    >
      <cart-navigation />
    </b-sidebar>
  </div>
</template>
<script>
import MainNavigation from "@/components/MainNavigation";
import MainFooter from "@/components/MainFooter";
import Cart from "@/assets/cart.js";
import {contextMenuListener} from "@/assets/common";
import AccountNavigation from "@/components/AccountNavigation";
import CartNavigation from "@/components/CartNavigation";
import Modal from "./components/Modal.vue";



export default {
  components: {CartNavigation, AccountNavigation, MainFooter, MainNavigation,Modal},
  data() {
    return {
      namedView: "Home",
      cartItems: Cart.countItems(),
      routeName: "",
      navigationKey: 0,
      showModal: false,
      confirmationMessage: 'Are you sure you want to leave?',
      
    }
  },
  mounted() {
    this.getRouteName();
    if (process.env.VUE_APP_DEPLOYMENT_ENV === "production") {
      contextMenuListener();
    }
  },
  watch: {
    isMenuOpen() {
      document.querySelector('body').style.overflowY = this.isMenuOpen ? 'hidden' : null;
    }
  },
  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${require('/src/assets/img/gabriel-garcia-marengo-kOqBCFsGTs8-unsplash.jpg')})`,
      };
    },
    isMenuOpen: {
      get: function () {
        return this.$store.state.isMenuOpen;
      },
      set: function (newIsMenuOpen) {
        return newIsMenuOpen;
      }
    },
    isCartOpen: {
      get: function () {
        return this.$store.state.isCartOpen;
      },
      set: function (newIsCartOpen) {
        return newIsCartOpen;
      }
    }
  },
  methods: {
    updateCartCount: function (totalCartItems) {
      this.cartItems = totalCartItems;
    },
    getRouteName() {
      this.routeName = this.$route.name;
    },
    reloadNavigation() {
      this.navigationKey++
    },

    closeMenu() {
      this.$store.commit('closeMenu');
    },
    showConfirmation(event) {
        event.returnValue = this.confirmationMessage;
        return this.confirmationMessage;
    },
  },
  beforeDestroy() {
    window.addEventListener('beforeunload', this.showConfirmation);
  },
  created(){
    window.addEventListener('beforeunload', this.showConfirmation);
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

#app {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Rubik', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -358px;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background: rgba(0,0,0,0.2);
    pointer-events: none;
  }

  //> * {
  //  z-index: 1;
  //}
}

body {
  background: #f2f7f9;
  overflow-x: hidden;

  &.modal-open {
    overflow: hidden;
  }
}

h1,
h2,
h3 {
  letter-spacing: -1px;
}

h2 {
  font-size: 1.5rem;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 1.5rem;
  }
}

.bg-white {
  background-color: #f1f1f1;
}

.bg-light {
  background-color: #f2f7f9;
}

.text-blue {
  color: #324a66;
}

.page {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: 100vh;
  background: #ffffff;
}

.form-row {
  margin: 0 auto 1rem;

  .form-check {
    display: block;
    min-height: 1.5rem;
    padding: 1em 1em 1em 2.2em;
    font-size: .875em;
    background: #f1efef;
  }
}

.form-actions {
  margin: 2rem 0;

  .btn {
    display: block;
    width: auto;
    margin: 0 0 1rem;
  }

  .btn-link {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}

.masthead {
  padding: 2rem 0;
  background: #e9e9e9;
}

.b-sidebar-outer {
  z-index: 4242 !important;
}

#sidebar-menu {
  &.b-sidebar {
    max-width: 100%;
    width: 320px;
    padding: 3.5rem 2.75rem 4rem;

    @media screen and (min-width: 465px) {
      width: 415px;
    }

    @media screen and (min-width: 768px) {
      height: 100%;
    }
  }
}

.btn,
button,
[type="button"],
[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.badge {
  + .badge {
    margin-left: .125rem;
  }
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-pill {
  padding-right: 0.6em !important;
  padding-left: 0.6em !important;
  border-radius: 10rem !important;
}

//modals
.modal-backdrop {
  opacity: 0.7 !important;
}

.modal-content {
  background: transparent !important;
}

.close-x {
  position: absolute;
  background: none;
  border: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #050404;
}

.modal-main {
  padding: 1.5rem;
  background: #ffffff;

  .close-x {
    right: 20px;
    bottom: 20px;

    @media (min-width: 768px) {
      color: #ffffff;
      left: -50px;
      right: auto;
      bottom: auto;
    }
  }
}

.modal-info {
  padding: 1.5rem 2rem;
  background: #b0c6e0;
  color: #6c4c4c;
  border-radius: 9px;

  .close-x {
    color: #ffffff;
    right: 20px;
    bottom: 20px;
  }

  .modal-info-img {
    vertical-align: top;
    width: 100%;
    border-radius: 9px;
  }

  h3 {
    margin-top: 0;
    color: #ffffff;
    font-size: 1.25rem;

    @media (min-width: 768px) {
      font-size: 1.375rem;
    }
  }

  h5 {
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: .875rem;
    color: #c42f2f;
  }
}

.modal-info-details {
  padding: 1rem 0;

  > div {
    margin: 0 0 1.5rem;

    &:last-child {
      margin: 0;
    }
  }
}

.modal-share {
  min-height: 350px;
  padding: 1.5rem 2rem;
  background: #ffffff;
  color: #000000;
  border-radius: 9px;
  text-align: center;

  .close-x {
    color: #000000;
    right: 20px;
    bottom: 20px;
  }

  h3 {
    margin-top: 0;
    color: #000000;
    font-size: 1.25rem;

    @media (min-width: 768px) {
      font-size: 1.375rem;
    }
  }

  h5 {
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: .875rem;
    color: #777777;
  }
}

.modal-purchase {
  padding: 1.5rem 2rem;
  border-radius: 9px;
  background: #ffffff;

  .close-x {
    right: 20px;
    top: 20px;
  }

  h3 {
    margin-top: 0;
    color: #000000;
    font-size: 1.25rem;

    @media (min-width: 768px) {
      font-size: 1.375rem;
    }
  }

  h5 {
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: .875rem;
    color: #777777;
  }

  .product-add-to-cart {
    border-top: 5px solid #fdc60d;
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin: 1rem 0;
    background: #f8f8f8;
    border-bottom-right-radius: 1em;
  }
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;

  @media (min-width: 768px) {
    padding: 1.5rem 0;
  }

  &.responsive {
    display: block;

    > div {
      margin: 0 0 .5rem;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;

      > div {
        margin: 0;
      }
    }
  }

  p {
    position: relative;
    color: #a3a0a0;
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
      background: #ddf2fc;
      color: #2466a9;
      padding: 0.5rem;
      border-radius: 8px;
      margin: 0;
      font-weight: 500;

      &::before {
        position: absolute;
        content: 'name:';
        top: -1.25rem;
        font-size: .75em;
        font-weight: 300;
      }
    }
  }

  .button-group {
    display: flex;

    [variant] {
      margin: 0 .5rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

}

//.modal-dialog-centered {
//  display: block !important;
//  top: 5%;
//
//  @media (min-width: 768px) {
//    top: 15%;
//  }
//
//  @media (min-width: 992px) {
//    top: 20%;
//  }
//}

// disable animation mobile/tablet
@media only screen and (max-width: 991px) {
  .card,
  .base-card,
  section img,
  section p,
  header *
  {
    opacity: 1 !important;
    transform: translate(0) scale(1) !important;
  }
}

</style>
