<template>
  <div class="page container-xl">
    <div class="row">
      <div id="upload-frm" class="mt-5 d-flex align-items-center justify-content-center" style="height: 50vh;">
        <form>
          <div class="mb-3 alert alert-warning d-flex align-items-center" role="alert">
            <em class="bi bi-exclamation-triangle-fill"></em> &nbsp;
            Click <strong>&nbsp;"Save"&nbsp;</strong>to complete Upload!
          </div>
          <div class="form-check form-switch mb-3">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="accessMode">
            <label class="form-check-label" for="flexSwitchCheckChecked">Free Image(s)</label>
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" placeholder="Add creator" aria-label="Creator"
                   aria-describedby="basic-addon1" v-model="creator">
          </div>
          <div class="mb-3">
            <DataList data-set="collection" :data-items="collections" :key="reloadKey" @handleInput="handleInput"/>
          </div>
          <div class="mb-3">
            <multi-select
                data-set="tags"
                :selected-options="tags"
                @handleInput="handleInput"
                :key="reloadKey"
            />
          </div>
          <div class="mb-3 fixed-content">
            <multi-image-upload
                data-set="images"
                :max-images-size="maxImagesSize"
                :max-images="maxImages"
                :storage-path="storagePath"
                :show-delete="false"
                :key="reloadKey"
                @handleUpload="handleUpload"
            />
          </div>
          <div class="d-flex justify-content-between">
            <button class="btn btn-md btn-outline-success" @click="handleSave">
              <em class="bi bi-save-fill"></em> Save <strong>({{ this.images.length }})</strong>
            </button>
            <button class="btn btn-md btn-outline-danger" @click="handleReset">
              <em class="bi bi-arrow-clockwise"></em> Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<!--suppress JSUnresolvedFunction -->
<script>
import {getImagesFromLocalStorage, updateImagesToLocalStorage} from "@/assets/common";
import {v4 as uuidV4} from 'uuid';
import DataList from "@/components/DataList";
import MultiSelect from "@/components/MultiSelect";
import MultiImageUpload from "@/components/MultiImageUpload";
import ImageService from "@/services/image/ImageService";

export default {
  name: "UploadAdmin",
  components: {
    DataList,
    MultiSelect,
    MultiImageUpload
  },
  data() {
    return {
      accessMode: false,
      creator: "",
      collection: "",
      tags: [],
      images: [],
      storagePath: "gallery",
      maxImagesSize: 2147483648,//2GB
      maxImages: 150,
      reloadKey: 0,
      collections: []
    }
  },
  async mounted() {
    this.collections = await this.getImageCollections();
  },
  methods: {
    getUploadedImageCount() {
      return Object.entries(getImagesFromLocalStorage()).map(image => image[1]).length;
    },
    async getImageInfo(image) {
      const filePath = image.imagePath;
      const fileUrl = image.imageUrl
      const fileName = filePath.split('\\').pop().split('/').pop();
      const fileNameArr = fileName.split(".");
      const fileTitle = fileNameArr[0];
      const fileExtension = fileNameArr.pop();

      return {
        "access_mode": this.accessMode,
        "asset_id": uuidV4(),
        "created_at": new Date().toISOString(),
        "creator": this.creator,
        "collection": this.collection,
        "description": "",
        "format": fileExtension,
        "likes": [],
        "public_id": filePath,
        "secure_url": fileUrl,
        "tags": this.tags,
        "title": fileTitle,
        "url": fileUrl
      };
    },
    async getImageCollections() {
      return ImageService.getImageCollections();
    },
    async saveImageInfoToFireCloud(imageInfo, imageIndex) {
      await ImageService.addImage(imageIndex, imageInfo);
    },
    async saveImageInfoToLocalStorage(imageInfo) {
      let storedImages = await getImagesFromLocalStorage();
      storedImages.push(imageInfo);
      await updateImagesToLocalStorage(storedImages);
    },
    handleInput(input) {
      this[input.key] = input.values;
    },
    handleUpload(upload) {
      this[upload.key] = upload.values;
    },
    handleSave(e) {
      e.preventDefault();

      if (this.images.length === 0) {
        return this.$toast.error(`No Image(s) uploaded, cannot be saved!`);
      }
      this.images.map(async (image) => {
        const imageInfo = await this.getImageInfo(image);

        await this.saveImageInfoToFireCloud(imageInfo, imageInfo.asset_id);
        await this.saveImageInfoToLocalStorage(imageInfo);

        await this.$toast.success(`Done saving....: ${imageInfo.title}.${imageInfo.format}`);
      });
      this.handleReset(e);
    },
    handleReset(e) {
      e.preventDefault();

      this.accessMode = false;
      this.creator = "";
      this.collection = "";
      this.tags = [];
      this.images = [];
      this.reloadKey++;
    },
  }
}
</script>
<style scoped>
#upload-frm {
  font-family: 'Rubik', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.fixed-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 25vh;
}
</style>
