<template>
  <b-button v-bind="$attrs" :class="{'btn-sm' : small}">
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    small: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  position: relative;
  font-size: .75rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  .b-icon {
    &.bi {
      position: relative;
      top: -1px;
      margin-right: 0.25rem;
      font-size: .75rem;

      @media (min-width: 768px) {
        top: -2px;
        font-size: 1rem;
      }
    }
  }
}
</style>