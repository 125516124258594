import Vue from 'vue';
import VueRouter from 'vue-router';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import Images from '../views/Images.vue';
import Cart from '../views/Cart.vue';
import Download from '../views/Download.vue';
import Product from '../views/Product.vue';
import PageNotFound from "../views/404";
import ImagesAdmin from "@/views/ImagesAdmin";
import ImagesEditingAdmin from "@/views/ImagesEditingAdmin";
import UploadAdmin from "@/views/UploadAdmin";
import Browse from "@/views/Browse";
import Photo from "@/views/Photo";
import {authGuard} from '@/auth/authGuard';
import SignInForm from "@/components/auth/SignInForm";
import SignUpForm from "@/components/auth/SignUpForm";
import ResetPassword from "@/components/auth/ResetPassword";
import ChangePassword from "@/components/auth/ChangePassword";
import Accounts from "@/views/Accounts";
import VerifyAccount from "@/components/auth/VerifyAccount";
import Orders from "@/views/Orders";
import CollectionAdmin from "@/views/CollectionAdmin.vue";
import Collections from "@/views/Collections.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            home: true,
        }
    },
    {
        path: '/images',
        name: 'Images',
        component: Images,
        meta: {
            showSearchNav: true,
        }
    },
    {
        path: '/browse/:imageId?',
        name: 'Browse',
        component: Browse
    },
    {
        path: '/photo/:imageId?',
        name: 'Photo',
        component: Photo,
        meta: {
            showSearchNav: true,
        }
    },
    {
        path: '/admin/images/edit',
        name: 'ImagesAdmin',
        component: ImagesAdmin,
        beforeEnter: authGuard
    },
    {
        path: '/product/:imageId',
        name: 'Product',
        component: Product
    },
    {
        path: '/admin/images/product/:imageId',
        name: 'ImagesEditingAdmin',
        component: ImagesEditingAdmin,
        beforeEnter: authGuard
    },

    {
        path: '/admin/images/upload',
        name: 'Upload',
        component: UploadAdmin,
        beforeEnter: authGuard
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        beforeEnter: authGuard
    },
    {
        path: '/download/:cartId',
        name: 'Download',
        component: Download,
        beforeEnter: authGuard
    },
    {
        path: '/accounts',
        component: Accounts,
        children: [
            {
                name: 'SignIn',
                path: 'signin',
                component: SignInForm,
            },
            {
                name: 'SignUp',
                path: 'signup',
                component: SignUpForm,
            },
            {
                name: 'ResetPassword',
                path: 'reset-password',
                component: ResetPassword,
            },
            {
                name: 'ChangePassword',
                path: 'change-password',
                component: ChangePassword,
                beforeEnter: authGuard
            },
            {
                name: 'VerifyAccount',
                path: 'verify-account',
                component: VerifyAccount,
            }

        ]

    },

    {
        path: '/admin/collections',
        name: 'CollectionsAdmin',
        component: CollectionAdmin,
    },
    {
        path: '/collections',
        name: 'Collections',
        component: Collections,
    },
    {path: "*", component: PageNotFound}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

export default router
