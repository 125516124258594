<template>
  <div>
    <div class="row">
      <hr>
      <div class="product-details">
        <div class="d-flex align-content-center align-items-center">
          <span class="badge bg-secondary">Title :</span>
          <div> {{ image.title }}</div>
        </div>
        <div class="d-flex  align-content-center align-items-center">
          <span class="badge bg-secondary">Description :</span>
          <div> {{ image.description.slice(0, 40) || image.title }}</div>
        </div>

        <div class="d-flex align-content-center align-items-center">
          <span class="badge bg-secondary">Collection :</span>
          <div> {{ image.collection }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductMetadata",
  props: {
    image: {
      type: Object,
    }
  }
}
</script>

<style scoped>

</style>
