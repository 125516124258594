import { IKImage, IKUpload } from "imagekitio-vue";


function getFilename(filePath) {
    return (filePath !== undefined ? filePath.split('/').pop() : "");
}

function getExtension(filePath) {
    return (filePath !== undefined ? filePath.split('.').pop() : "");
}

function getImageKitUrl(path, dimensions = null, clip = false) {
    const imageKitBaseUrl = `https://ik.imagekit.io/l7yvb6qgi/`;
    const filename = getFilename(path);
    const imageKitUrl = `${imageKitBaseUrl}${filename}`;
    let image_path = `${imageKitUrl}?tr=fo-center,cm-resize,c-force`;
    if (clip) {
        return image_path
    } else if (!clip) {
        return image_path
    } else {
        return `${imageKitBaseUrl}/tr:oi-thome-logo-big.png/${filename}`
    }

}

export {
    getImageKitUrl
};
