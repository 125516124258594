<template>
  <div class="d-flex justify-content-center">
    <div class="col-8">
      <div>
        <b-jumbotron
            class="m-0"
            fluid
            style="background-color: #f5f5f5"
            header="Collections"
            lead=""></b-jumbotron>
        <div class="d-flex justify-content-end">
          <b-button
              variant="primary"
              @click="showCollectionModal"
              class="m-2">
            New collection
          </b-button>
        </div>
      </div>
      <div>
        <b-alert show variant="danger" v-if="!collections.length">
          No collections found. Create a new collection to get started
        </b-alert>

      </div>
      <featured-collections :prop-collections="collections" :prop-is-admin="true" @edit-collection="editCollection"
                            @delete-collection="deleteCollection"></featured-collections>
      <div>
        <b-modal id="create-collection" :title="collection.searchQuery? 'Edit collection':'New collection'"
                 body-bg-variant="light" header-bg-variant="light"
                 footer-bg-variant="light" @ok="createCollection" size="lg"
                 :ok-title="collection.name.length<=0? 'Next' : 'Create'"
                 :ok-disabled="collectionImages.length <=0" hide-footer>


          <b-tabs content-class="mt-3">
            <b-tab title="Select photos" active>
              <div class="step-one">
                <b-form-group label="Search images" label-for="search">
                  <b-input-group>
                    <b-form-input
                        id="search"
                        v-model="searchQuery || collection.searchQuery"
                        required
                        placeholder="Search images">
                    </b-form-input>
                    <b-input-group-append v-if="searchQuery || collection.searchQuery">
                      <b-button variant="info" @click="setCollectionImages">Search</b-button>
                    </b-input-group-append>
                  </b-input-group>


                </b-form-group>
                <div class="d-flex flex-wrap col-12">
                  <div class="mt-2 col-12" v-if="collectionImages.length">
                    <b-alert show variant="success">
                      Search has matched {{ collectionImages.length }} images. Select an image to set it as lead image
                    </b-alert>
                    <div class="d-flex">
                      <div v-if="collection.leadImage">
                        <img :src="collection.leadImage" alt="Collection image" height="200" width="200" class="p-1">
                      </div>
                      <div class="d-flex flex-wrap overflow-scroll" style="height: 200px">
                        <div v-for="image in collectionImages.slice(0,20)" :key="image.id">
                          <img :src="image.url" :alt="image.title" height="100" width="100" class="p-1 hover-large"
                               @click="setCollectionLeadImage(image)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!collectionImages.length" class="mt-2 col-12">
                    <b-alert show variant="danger">
                      No matching images found
                    </b-alert>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Name collection" active>
              <div class="step-two">
                <form>
                  <b-form-group label="Collection name">
                    <b-form-input
                        id="collection-name"
                        v-model="collection.name"
                        required
                        placeholder="Collection name">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Collection description">
                    <b-form-textarea
                        id="collection-description"
                        v-model="collection.description"
                        required
                        placeholder="Collection name">
                    </b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Collection lead image" v-if="collection.leadImage">
                    <img :src="collection.leadImage" alt="Collection image" height="250" width="250"
                         class="hover-large">
                  </b-form-group>
                  <b-form-group label="Publish collection" v-slot="{ ariaDescribedby }">
                    <b-form-radio v-model="collection.published" :aria-describedby="ariaDescribedby" :value="true">
                      Yes
                    </b-form-radio>
                    <b-form-radio v-model="collection.published" :aria-describedby="ariaDescribedby" :value="false">
                      No
                    </b-form-radio>
                  </b-form-group>
                </form>
              </div>
            </b-tab>
          </b-tabs>
          <div class="d-flex justify-content-between mt-5">
            <b-button variant="secondary" @click="hideCollectionModal">Cancel</b-button>
            <b-button variant="primary"
                      :disabled="collection.description.length <= 0 || collection.name.length <= 0 || collection.leadImage.length <= 0"
                      @click="createCollection">Save collection
            </b-button>
          </div>
        </b-modal>
      </div>

    </div>
  </div>

</template>

<script>
import FeaturedCollections from "@/components/FeaturedCollections.vue";

export default {
  name: "CollectionAdmin",
  components: {FeaturedCollections},
  data() {
    return {
      searchQuery: "",
      images: [],
      collectionImages: [],
      okayDisabled: true,
      activeSelectPhotos: false,
      activeSetCollection: true,
      collections: [],
      collection: {
        name: "",
        description: "",
        searchQuery: "",
        leadImage: "",
        published: false,
        images: [],
        size: 0
      }
    }
  },
  mounted() {
    this.getCollections()
    this.getImages()
  },
  methods: {
    async getCollections() {
      this.collections = await this.$store.dispatch("collections/getCollections", {published: false})
    },
    setCollectionLeadImage(image) {
      this.collection.leadImage = image.url
      if (this.collection.leadImage.length > 0) {
        this.$toast.success("Lead image has been changed")
      }
    },
    async getImages() {
      this.images = await this.$store.dispatch("images/getImages")
    },
    setCollectionImages() {
      this.collectionImages = this.searchImages()
      this.collection.images = this.collectionImages.slice(0, 20)
      this.collection.leadImage = this.collectionImages[0].url
    },
    searchImages() {
      if (this.searchQuery || this.collection.searchQuery && this.searchQuery.length >= 3 || this.collection.searchQuery.length >= 3) {
        let queryString = this.searchQuery || this.collection.searchQuery
        return this.images.filter((item) => {
          let found = queryString
              .trim()
              .toLowerCase()
              .split(" ")
              .some((v) => "tags" in item && item.tags.map(tag => {
                return tag.trim().toLowerCase().split(" ").join("-");
              }).map(item => item.match(v)).some(obj => Array.isArray(obj)));
          return found
        });
      } else {
        return this.images;
      }
    },
    hideCollectionModal() {
      this.$bvModal.hide("create-collection")
    },
    showCollectionModal() {
      this.$bvModal.show("create-collection")
      console.log(this.collection)
    },
    async createCollection() {
      if (this.searchQuery.length <= 0) {
        this.searchQuery = this.collection.searchQuery
      }
      this.collection.searchQuery = this.searchQuery
      this.collection.size = this.collectionImages.length
      await this.$store.dispatch("collections/addCollection", this.collection)
      await this.getCollections()
      this.$toast.success("Collection has been created")
      this.hideCollectionModal()
    },
    editCollection(collection) {
      console.log(collection)
      this.collection = collection
      // this.searchQuery = collection.searchQuery
      this.showCollectionModal()
    },
    async deleteCollection(collectionId) {
      let confirmDelete = confirm("Are you sure you want to delete this collection?")
      if (!confirmDelete) {
        return
      }
      await this.$store.dispatch("collections/deleteCollection", collectionId)
      await this.getCollections()
      this.$toast.error("Collection has been deleted")

    },
  },
}
</script>

<style scoped>
.hover-large:hover {
  transition: transform .2s;
  transform: scale(2.5);
}

</style>