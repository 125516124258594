<template>
  <div class="page container-xl">
    <page-header
        title="Download Items"
        description="View and download your purchased items."
    />
    <download-item v-for="(item, index) in cartItems" :key="index" :item="item"/>
  </div>
</template>
<script>

import DownloadItem from "@/components/DownloadItem";
import OrderService from "@/services/order/OrderService";
import PageHeader from "@/components/PageHeader";

export default {
  name: 'Download',
  components: {
    PageHeader,
    DownloadItem
  },
  mounted() {
    this.cartId = this.$route.params.cartId
    this.user = this.$store.state.user
    this.getOrderItems(this.user.id, this.cartId);
  },
  data() {
    return {
      cartId: this.$route.params.cartId,
      cartItems: [],
      user: this.$store.state.user
    }
  },
  methods: {
    async getOrderItems(userId, cartId) {
      let OrderId = `${userId}/${cartId}`
      let cart = await OrderService.getOrderDetails(OrderId)
      this.cartItems = cart.items
    }
  }
}
</script>
<style scoped>

</style>
