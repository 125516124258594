<!--suppress ALL -->
<template>
  <div class="page container-xl">
    <page-header
        title="Shopping Cart"
        :description="`Items selected for purchase: ${cartItems.length}`"
    />
    <cart-item
        v-for="(item, index) in cartItems"
        :key="index"
        :item="item"
        @updateCartCount="updateCartCount"
    />
    <card v-if="!cartItems.length" theme="message">
      No items in the cart
    </card>
    <card
        v-if="cartItems.length"
        theme="reverse"
        align="right"
    >
      <stripe-checkout
          ref="checkoutRef"
          :cancel-url="cancelURL"
          :line-items="lineItems"
          :pk="publishableKey"
          :success-url="successURL"
          mode="payment"
          @loading="v => loading = v"
      />
      <cart-button :cart-button="cartButton" :key="reloadKey"/>
    </card>
  </div>
</template>
<!--suppress JSUnresolvedFunction -->
<script>
import CartButton from "../components/CartButton";
import CartItem from "../components/CartItem";
import Cart from "@/assets/cart.js";
import {StripeCheckout} from '@vue-stripe/vue-stripe';
import {v4 as uuidv4} from 'uuid';
import OrderService from "@/services/order/OrderService";
import PageHeader from "@/components/PageHeader";
import Card from "@/components/Card";

export default {
  name: 'Cart',
  components: {
    Card,
    PageHeader,
    CartButton,
    CartItem,
    StripeCheckout
  },
  mounted() {
    this.cartItems = Cart.getItems();
    this.cartTotal = this.getCartTotal();
    this.cartButton = this.getCartButton(this.getCartType());
    this.lineItems = this.getLineItems(this.cartItems);
    this.completeCheckoutEvent(this.checkoutStatus, this.getCheckoutMessage(this.checkoutStatus));
  },
  data() {
    return {
      cartButton: {},
      cartItems: [],
      cartTotal: 0,
      publishableKey: `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`,
      loading: false,
      lineItems: [],
      successURL: `${location.href}#success`,
      cancelURL: `${location.href}#failed`,
      checkoutStatus: `${location.hash}`,
      cartId: "",
      reloadKey: 0,

    }
  },
  computed: {
    cartIsEmpty: function () {
      return this.cartItems.length <= 0;
    }
  },
  methods: {
    getCartType: function () {
      return (this.cartTotal <= 0 ? "download" : "checkout");
    },
    getCartButton: function (cartType) {
      const cartButtons = [
        {
          "class": "btn btn-block btn-success",
          "clickEvent": this.checkoutClickEvent,
          "text": `Proceed to Pay $${this.cartTotal}`,
          "action": "checkout"
        },
        {
          "class": "btn btn-block btn-info",
          "clickEvent": this.downloadClickEvent,
          "text": "Download Image(s)",
          "action": "download"
        },
      ];
      return cartButtons.find(button => button.action === cartType);
    },
    updateCartCount: function (totalCartItems) {
      this.setCartTotal(this.getCartTotal());
      this.setLineItems(this.getLineItems(this.cartItems));

      this.updateCartButton();

      this.$emit('updateCartCount', totalCartItems);
    },
    updateCartButton() {
      this.cartButton = this.getCartButton(this.getCartType());
      this.reloadKey++;
    },
    setCartTotal: function (cartTotal) {
      this.cartTotal = cartTotal;
    },
    getCartTotal: function () {
      let cartTotal = this.cartItems.reduce((acc, val) => acc + parseFloat(val.license.price), 0);
      return (cartTotal === 0 ? 0 : cartTotal.toFixed(2));
    },
    setLineItems: function (lineItems) {
      this.lineItems = lineItems;
    },
    getLineItems(cartItems) {
      let lineItems = [];
      let lineItemsCount = {}

      for (const cartItem of cartItems) {
        const price = cartItem.license.id;
        const isPresent = (price in lineItemsCount);
        let quantity = 0;

        if (isPresent) {
          quantity = lineItemsCount[price];
          quantity++;
          const lineItemIndex = lineItems.findIndex(lineItem => lineItem.price === price);
          lineItems[lineItemIndex]["quantity"] = quantity;
        }
        if (!isPresent) {
          quantity = 1;
          lineItemsCount[price] = quantity;
          lineItems.push({
            "price": price,
            "quantity": quantity
          });
        }
      }

      return lineItems;
    },
    downloadClickEvent: function () {
      if (this.cartIsEmpty) {
        this.$toast.info("No Items in Cart!");
      }
      if (!this.cartIsEmpty) {
        this.saveCartAndDownload();
      }
    },
    sendCheckoutMetrics: function () {
      for (let item of this.cartItems) {
        window.pintrk('track', 'checkout', {
          value: item.license.price,        // The value of the product added to the cart
          order_quantity: 1,      // The quantity of the product added to the cart
          currency: 'USD',        // The currency of the transaction
          product_id: item.license.id     // The ID of the product added to the cart
        });
      }
    },
    checkoutClickEvent: function () {
      if (this.cartIsEmpty) {
        this.$toast.info("No Items in Cart!");
      }
      if (!this.cartIsEmpty) {
        if (this.$store.state.isLoggedIn) {
          this.$refs.checkoutRef.redirectToCheckout();
          return
        }
        this.$toast.info("Please sign in or create account first.");
        this.$store.commit('openMenu')
        this.$store.commit('setActiveMenuView', 'signin')
      }
    },
    getCheckoutMessage: function (checkoutStatus) {
      const checkoutMessage = new Map([
        ["#success", "Payment was successful"],
        ["#failed", "Payment failed!"]
      ]);

      return checkoutMessage.get(checkoutStatus) || '';
    },
    async saveCartToDatabase(cartId) {
      let user = this.$store.state.user
      let items = Cart.getItems()
      let order = {
        id: `${user.id}/${cartId}`,
        items: items,
        user: {
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email
        },
        created: Date.now()
      }
      await OrderService.saveOrder(order)
    },
    clearCart: function () {
      Cart.clearItems();
      this.cartItems = Cart.getItems();
      this.updateCartCount(this.getCartTotal());
    },
    navigateToDownload(cartId) {
      this.sendCheckoutMetrics();
      this.$router.push({name: "Download", params: {cartId: cartId}});
    },
    saveCartAndDownload() {
      this.cartItems = Cart.getItems();
      this.cartId = uuidv4();
      this.saveCartToDatabase(this.cartId);
      this.navigateToDownload(this.cartId);
      this.clearCart();
    },
    completeCheckoutEvent: function (checkoutStatus, checkoutMessage) {
      if (checkoutStatus === "#failed") {
        this.$toast.error(checkoutMessage);
      }
      if (checkoutStatus === "#success") {
        this.sendCheckoutMetrics();
        this.saveCartAndDownload();
        this.$toast.success(checkoutMessage);
      }
    }
  }
}
</script>
<style scoped>
.chart-list {
  background-color: #EEE;
}
</style>
