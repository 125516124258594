<template>
  <span class="loader" :class="[{large: large}, {block: block}]" />
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>

$color: #fec70a;

.loader {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;

  border: .2em solid transparent;
  border-left-color: $color;
  border-right-color: $color;
  border-radius: 50%;
  animation: 1s rotate linear infinite;

  &.large {
    width: 4rem;
    height: 4rem;
    border-width: 0.65rem;
  }

  &.block {
    display: block;
    margin: 0 auto;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>