<template>
  <form
      @submit="handleSubmit"
      :class="{
        'nav-search' : theme === 'nav'
    }"
  >
    <div class="input-group">
      <div class="input-group-prepend border-0">
        <button id="button-addon4" type="submit" class="btn search-btn">
          <b-icon icon="search" />
        </button>
      </div>
      <input
          type="search"
          :placeholder="placeHolder"
          aria-describedby="button-addon4-search"
          class="form-control"
          v-model="searchString"
          @input="handleSearch"
          @keydown.enter="handleSubmit"
      >
    </div>
  </form>
</template>
<script>
export default {
  name: 'SearchBar',
  props: {
    placeHolder: {
      type: String,
      default: 'Search for images'
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      searchString: null
    }
  },
  mounted() {
    this.getQueryString();
  },
  methods: {
    handleSearch() {
      this.$emit("handleSearch", this.searchString);
    },
    getQueryString() {
      this.searchString = this.$route.query.search
      this.handleSearch()
    },
    handleSubmit(e) {
      e.preventDefault();
      if (this.searchString) {
        this.$emit("handleSubmit", this.searchString);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.has-search {
  .form-control {
    padding-left: 2.375rem;
  }

  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
}

.form-control {
  color: #777777;
  background: transparent;
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  &:focus {
    color: #222222;
    background: transparent;
    border: 0;
    box-shadow: none;
  }
}

.search-btn {
  background: none;
  border: 0;
  border-radius: 8px;
  color: #222222;

  &:active,
  &:hover,
  &:focus {
    background: none;
    color: #222222;
  }
}

.input-group {
  padding: .25rem;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
}

.dark-searchbar {
  .input-group {
    color: #ffffff;
    background-color: #2465a9;
    border-color: #40a4d7;
    border-radius: 8px;

    .search-btn {
      color: #083260;

      &:hover {
        color: lighten(#083260, 10%);
      }
    }

    .form-control {
      padding-left: .35rem;
      color: #ffffff;
      background-color: transparent;

      &:focus {
        background-color: rgba(255,255,255,.0875);
        box-shadow: none;
      }

      &::placeholder {
        color: #ffffff;
        opacity: .5;
      }
    }
  }

}
</style>
