<!--suppress ALL -->
<template>
  <b-navbar
      toggleable="lg"
      :type="this.$route.meta.home ? 'dark' : 'light'"
  >
    <b-container fluid="xl">
      <div class="nav-with-search">
        <b-navbar-brand to="/">
          <logo
              text-color="dark"
              class="logo"
          />
        </b-navbar-brand>

        <div v-if="showSearchNav" class="search-area">
          <search-bar theme="nav" />
        </div>
      </div>

      <b-navbar-toggle target="nav-collapse">
          <span class="navbar-toggler-icon">
            <div id="hamburger">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </span>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
<!--          <b-nav-item to="/" exact exact-active-class="active">Home</b-nav-item>-->
          <b-nav-item to="/images" exact exact-active-class="active">Images</b-nav-item>
          <b-nav-item to="/collections" exact exact-active-class="active">Collections</b-nav-item>
          <div
              id="cart"
              class="nav-item nav-item-cart"
              @click="navigateToPage('Cart')"
          >
            <a class="nav-link">
              <span class="d-lg-none">Cart</span>
              <b-icon icon="cart" class="bi"></b-icon>
              <span class="badge rounded-pill bg-danger">
                  {{ cartItems }}<span class="visually-hidden">items</span>
                </span>
            </a>
          </div>

          <div
              class="nav-item nav-item-account"
              :class="{'active': isLoggedIn}"
          >
            <a
                class="nav-link sidebar-toggle"
                href="#"
                @click="openMenu"
            >
              <b-icon icon="person-circle" class="bi"></b-icon> <span>{{ isLoggedIn ? 'My Account' : 'Sign In'}}</span>
            </a>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>
<script>
import router from "@/router";
import Logo from "@/components/Logo";
import SearchBar from "@/components/SearchBar";

export default {
  name: "MainNavigation",
  components: {SearchBar, Logo},
  props: {
    cartItems: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      activeMenu: "home",
      scrollPosition: null,
      showMenu: false,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
    showSearchNav() {
      return this.$route.meta.showSearchNav;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    navigateToPage(page) {
      router.push({name: page});
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    openMenu() {
      this.$store.commit('openMenu');
    },
  }
}
</script>
<style scoped lang="scss">
.navbar {
  justify-content: space-between;
  height: 80px;
  border-bottom: 7px solid transparent;
  z-index: 120;

  &.scrolled {
    position: fixed;
    width: 100%;
    transition: background-color 200ms;
    z-index: 1020;
  }
}

.nav-with-search {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem;
  font-size: 1rem;
  color: black;
  border: 0;
  border-radius: 0.25rem;
  transition: box-shadow .15s ease-in-out;

  .navbar-toggler-icon {
    background: none;
    width: auto;
    height: auto;

    svg {
      height: auto;
      width: 45px;
    }
  }

  &:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
}

.navbar-nav {
  position: relative;

  .nav-link {
    font-weight: 400;
    cursor: pointer;

    .bi {
      font-size: 28px;
      vertical-align: top;
    }

    @media (min-width: 992px) {
      position: relative;
      padding-bottom: 20px;
      border-bottom: 7px solid transparent;
      top: 10px;
    }
  }

  @media (min-width: 992px) {
    height: inherit;
  }
}

//account

.nav-item-account {

  @media (min-width: 992px) {
    align-self: center;

    a {
      span {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    .bi {
      display: none;
    }
  }

  &.active {
    .bi {
      border-radius: 50px;
    }
  }
}

.navbar-light {
  position: fixed;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 1px 0 #f7f7f7;

  + ::v-deep .main-content {
    margin-top: 80px;
  }

  .navbar-nav {
    .nav-link {
      color: #222222;

      &:hover {
        color: rgba(0,0,0, 0.8);
      }

      &.active {
        @media (min-width: 992px) {
          color: #222222;
          border-color: #40a4d7;
        }
      }
    }
  }

  .navbar-toggler {
    color: black;

    &[aria-expanded="true"] {
      color: white;
    }
  }

  .nav-item-account {
    &.active {
      .bi {
        background: rgba(64, 164, 215, 0.3);
      }
    }
  }
}

.navbar-dark {
  background: transparent;

  .navbar-nav {
    .nav-link {
      color: #ffffff;

      &:hover {
        color: rgba(255,255,255, 0.8);
      }

      &.active {
        @media (min-width: 992px) {
          color: #ffffff;
          border-color: #40a4d7;
        }
      }
    }
  }

  .nav-item-account {
    &.active {
      .bi {
        background: rgba(64, 164, 215, 0.6);
      }
    }
  }
}

.navbar-brand {
  @media (min-width: 768px) {
    padding-top: 10px;
  }

  .logo {
    cursor: pointer;
  }
}

.nav-link {
  cursor: pointer;
}

.nav-item-cart {
  position: relative;

  a {
    position: relative;

    @media (min-width: 992px) {
      font-size: 18px;
    }
  }

  .icon-cart {
    display: none;

    @media (min-width: 992px) {
      display: inline-block;
    }
  }

  @media (min-width: 992px) {
    margin-right: 10px;
  }

  @media (max-width: 991px) {
    .bi {
      display: none;
    }
  }
}

.badge {
  position: relative;
  margin-left: 5px;
  top: -2px;
  padding: 0.35em 0.55em;
  transform: none;

  @media (min-width: 992px) {
    position: absolute;
    left: 50%;
    top: 7px;
    margin: 0;
    font-size: .55em;
  }
}

.navbar-collapse {
  @media (min-width: 992px) {
    flex-basis: content;
    flex-grow: inherit;
    margin: 0;
    height: 100%;

    &.collapsing,
    &.show {
      overflow: visible;
      box-shadow: none;
    }

  }

  @media (max-width: 991px) {
    &.collapsing,
    &.show {
      z-index: 1023;
      background: black;
      margin: 7px -30px 0;
      box-shadow: rgb(0 0 0 / 53%) 0px 25px 25px 10px;
      //border-bottom: 10px solid #1c1c1c;

      .navbar-nav {
        padding: 0;
        margin: 0;

        &:first-child {
          padding-bottom: 0;
        }

        &:last-child {
          padding-top: 0;
        }
      }

      .nav-link {
        margin: 0 0 1px;
        cursor: pointer;
        background: rgba(255,255,255,0.1);
        padding: 1rem 2rem;
        color: white;

        &:hover,
        &:active {
          color: white;
          background: rgba(255,255,255,0.05);
          transform: scale(1.025);
          transition: all 250ms ease-in-out;
        }

        &::before {
          margin-right: 10px;
        }
      }
    }
  }
}

// search
.search-area {
  display: none;
  padding-top: 6px;

  ::v-deep {
    .input-group {
      background: #f7f7f7;
      border: 0;
    }
  }
}

// hamburger
#hamburger {
  width: 40px;
  height: 30px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#hamburger span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: #030303;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#hamburger span:nth-child(1) {
  top: 0px;
}

#hamburger span:nth-child(2),
#hamburger span:nth-child(3) {
  top: 12px;
}

#hamburger span:nth-child(4) {
  top: 24px;
}

.navbar-toggler {
  &[aria-expanded="true"] {
    #hamburger span:nth-child(1) {
      top: 12px;
      width: 0%;
      left: 50%;
    }

    #hamburger span:nth-child(2) {
      transform: rotate(45deg);
    }

    #hamburger span:nth-child(3) {
      transform: rotate(-45deg);
    }

    #hamburger span:nth-child(4) {
      top: 12px;
      width: 0%;
      left: 50%;
    }
  }
}
</style>
