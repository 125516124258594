<template>
  <section id="about">
    <div class="part part-one">
      <b-container fluid="xl">

        <b-row>
          <b-col md="4">
            <img
                src="/brett-zeck-eyfMgGvo9PA-unsplash.jpeg"
                alt="Photo by Brett Zeck"
                data-aos="fade-right"
            />
          </b-col>
          <b-col md="8">


            <p class="text-lg" data-aos="fade-left">
              These rare, descriptive, detailed and dramatic photos are from intelligent
              adventure world travels with exploration across the globe, spanning 50 years.

            </p>



          </b-col>
        </b-row>

      </b-container>
    </div>
    <div class="part part-two">
      <b-container fluid="xl">

        <b-row>
          <b-col md="8">
            <p class="text-lg" data-aos="fade-right">
              Many of the images are specific journey’s scientific & medical research and
              non-profit anthropology expeditions including educational treks and safaris.
              Destinations are off the beaten path with detours to parts known and unknown.

            </p>
          </b-col>
          <b-col md="4">
            <img
                src="/dariusz-sankowski-3OiYMgDKJ6k-unsplash.jpeg"
                alt="Photo by Dariusz Sankowski"
                data-aos="fade-left"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="part part-three">
      <b-container fluid="xl">

        <b-row>

          <b-col md="4">
            <img
                src="/nikon-f-comp.jpg"
                alt="Nikon F camera"
                data-aos="fade-right"
            />
          </b-col>

          <b-col md="8">

            <p class="text-md" data-aos="fade-left">

              The images are journalistically candid. While a few people may pose for a
              picture, the photographs are candid, spontaneous without any staging. Unlike
              most stock imagery, none of these have been altered, filtered, cropped or "Photoshopped".


            </p>
            <p data-aos="fade-right">

              The uniqueness of these photos is that they were all taken before the
              development of the Internet, cell phones and digital camera technologies.

            </p>
            <p data-aos="fade-left">

              They are captured on non-digital film, primarily Ektachrome/Kodachrome 35 mm
              color slide transparencies using Nikon Pro F series mechanical bodies with
              Nikkor lenses.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>



  </section>
</template>

<script>
export default {
  name: 'SectionAbout',
}
</script>

<style lang="scss" scoped>
section {
  background: #eeeeee;
  margin: 0;
  padding: 0;
}

.text-md {
  font-size: 1.2rem;
}

.text-lg {
  font-size: 1.5rem;
}

p {
  font-weight: 200;

  &:last-child {
    margin-bottom: 0;
  }
}

.part {
  padding: 2rem 1rem;

  img {
    width: 100%;
    margin-bottom: 1.5rem;

    @media (min-width: 992px) {
      margin: 0 auto;
    }
  }

  @media (min-width: 992px) {
    padding: 4rem 0;
  }
}

.part-one {
  color: #212529;
  background-color: #fbd334;
  background-image: url("/section-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;

  img {
    mix-blend-mode: color-burn;
  }
}

.part-two {
  color: #ffffff;
  background-color: #886b3d;

  img {
    mix-blend-mode: lighten;
    margin: 1.5rem 0 0;

    @media (min-width: 992px) {
      margin: 0 auto;
    }
  }

}

.part-three {
  color: #212529;
  background-color: #ffffff;

}
</style>