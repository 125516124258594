<template>
  <div class="cart-item">
    <card>
      <div class="row">
        <div class="col-6 col-md-3">
          <IKImage urlEndpoint="https://ik.imagekit.io/l7yvb6qgi/" :path="getFilename(item.image.public_id)"
                   :transformation="transformation"/>
        </div>
        <div class="col-6 col-md-5">
          <p><span>License:</span> {{ item.license.name }}</p>
          <p><span>Size:</span> {{ item.license.description }} px</p>
        </div>
        <div class="col-md-3">
          <h5>${{ item.license.price }}</h5>
        </div>
        <div class="col-md-1 text-end">
          <button
              class="close-btn"
              @click="deleteItem(item.image.id)">
            <b-icon icon="x-circle"/>
          </button>
        </div>
      </div>
    </card>
    <confirm-dialog ref="confirmDialog"/>
  </div>
</template>
<!--suppress JSUnresolvedFunction -->
<script>
import Cart from "@/assets/cart.js";
import Card from "@/components/Card";
import ConfirmDialog from "@/components/ConfirmDialog";
import {IKImage} from "imagekitio-vue";

export default {
  name: "CartItem",
  components: {ConfirmDialog, Card, IKImage},
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      transformation: [{
        "height": "120",
        "quality": "20"
      }]
    }
  },
  methods: {
    getFilename(filePath) {
      return (filePath !== undefined ? filePath.split('/').pop() : "");
    },
    async deleteItem(itemId) {
      let text;
      const ok = await this.$refs.confirmDialog.show({
        title: 'Delete Item',
        message: 'Are you sure you want to delete this item?',
        okButton: 'Delete',
      })
      if (ok) {
        let itemName = this.item.license.name;
        Cart.removeItem(itemId, itemName);
        this.$emit('updateCartCount', Cart.countItems());
        text = "Item was removed from Cart!";
      } else {
        text = "You cancelled removing from Cart!";
      }
      this.$toast.info(text);
    },
  }
}
</script>
<style lang="scss" scoped>
.close-btn {
  background: transparent;
  border: 0;
  color: #dc3644;
}

.row {
  @media (min-width: 576px) {
    align-items: center;
    justify-content: space-between;
  }
}

h5 {
  margin: 0;

  @media (min-width: 576px) {
    margin: 1rem 0 0;
  }

  @media (min-width: 768px) {
    margin: 0;
  }
}

p {
  font-size: .875em;
  margin: 0 0 .5rem;

  span {
    display: block;
    font-size: .875em;
    color: #777777;

    @media (min-width: 576px) {
      display: inline-block;
    }
  }

  @media (min-width: 576px) {
    font-size: 1rem;
  }
}
</style>
