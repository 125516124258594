<template>
  <div class="row d-flex justify-content-between mt-3 px-5">
    <div class="col-auto">
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: this.isPreviousButtonDisabled}">
            <a class="page-link p-2" @click="handleButtonClick(-1)">
              <em class="bi bi-chevron-left"></em>
              PREVIOUS
            </a>
          </li>
          <li class="page-item mx-2">
            <select class="p-2" v-model="currentPage" @change="handleButtonClick(0)">
              <option v-for="page in pageList" :key="page" :class="(page === currentPage) ? 'selected' : '' ">
                {{ page }}
              </option>
            </select>
          </li>
          <li class="page-item py-2">
            of {{ pageList.length }}
          </li>
          <li class="page-item mx-2" :class="{ disabled: this.isNextButtonDisabled}">
            <a class="page-link p-2" @click="handleButtonClick(1)">
              NEXT
              <em class="bi bi-chevron-right"></em>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-auto">
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="itemPerPageSelect" class="col-form-label">Items per page</label>
        </div>
        <div class="col-auto">
          <select class="form-select" id="itemPerPageSelect" v-model="numberPerPage"
                  @change="handlePagination(startPage)">
            <option :value="pageLimit" v-for="pageLimit in pageLimits" :key="pageLimit">{{ pageLimit }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    startPage: {
      type: Number,
      default: 1
    },
    numberOfItems: {
      type: Number
    },
    ItemsPerPage: {
      type: Number,
      default: 24
    },
    pageLimits: {
      type: Array,
      default: () => ([24, 48, 96])
    },
  },
  data() {
    return {
      numberPerPage: this.ItemsPerPage,
      currentPage: this.startPage,
      pageShift: 1
    }
  },
  computed: {
    maxPages: function () {
      return this.getMaxPages(this.numberOfItems, this.numberPerPage);
    },
    pageList: function () {
      return this.getPageList(this.maxPages, this.pageShift)
    },
    isPreviousButtonDisabled: function () {
      return this.currentPage === 1;
    },
    isNextButtonDisabled: function () {
      return this.currentPage === this.maxPages || this.maxPages === 0;
    }
  },
  methods: {
    getMaxPages(numberOfItems, numberPerPage) {
      numberOfItems = (numberOfItems < 0 ? 0 : numberOfItems);
      return Math.ceil(numberOfItems / numberPerPage);
    },
    getPageList(maxPages, pageShift) {
      maxPages = (maxPages < 0 ? 0 : maxPages);
      return Array(maxPages).fill(0).map((_, i) => i + pageShift);
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    getItemStartIndex(page, numberPerPage, pageShift) {
      const itemStartIndex = (page - pageShift) * numberPerPage;
      return (itemStartIndex < 0 ? 0 : itemStartIndex);
    },
    getItemEndIndex(page, numberPerPage, numberOfItems, pageShift) {
      const itemEndIndex = this.getItemStartIndex(page, numberPerPage, pageShift) + numberPerPage;
      return (itemEndIndex >= numberOfItems ? numberOfItems : itemEndIndex);
    },
    handleButtonClick(shiftIndex) {
      const newCurrentPage = parseInt(this.currentPage) + parseInt(shiftIndex);
      this.handlePagination(newCurrentPage);
    },
    handlePagination(newCurrentPage) {
      this.setCurrentPage(newCurrentPage);
      this.$emit("handlePagination", {
        "itemStartIndex": this.getItemStartIndex(newCurrentPage, this.numberPerPage, this.pageShift),
        "itemEndIndex": this.getItemEndIndex(newCurrentPage, this.numberPerPage, this.numberOfItems, this.pageShift),
        "currentPage": newCurrentPage
      });
    }
  }
}
</script>
<style scoped>
</style>
