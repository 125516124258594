import { render, staticRenderFns } from "./Photo.vue?vue&type=template&id=7d457f36&scoped=true&%3Akey=renderKey.related&"
import script from "./Photo.vue?vue&type=script&lang=js&"
export * from "./Photo.vue?vue&type=script&lang=js&"
import style0 from "./Photo.vue?vue&type=style&index=0&id=7d457f36&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d457f36",
  null
  
)

export default component.exports