class Cart {
    constructor() {
        this.name = "picha_cart";
        this.items = this.getFromStorage();
    }

    addItem(item) {
        const itemMatch = this.getItem(item);
        if (!itemMatch) {
            this.items.push(item);
            this.saveToStorage(this.name, this.items);
        }
    }

    getItem(item) {
        this.items = this.getFromStorage();
        return this.items.find(o => o.image.id === item.image.id && o.license.name === item.license.name);
    }

    getItems() {
        return this.items;
    }

    countItems() {
        return this.items.length;
    }

    removeItem(itemId, itemName) {
        for (let i = 0; i < this.countItems(); i++) {
            if (this.items[i].image.id === itemId && this.items[i].license.name === itemName) {
                this.items.splice(i, 1);
            }
        }
        this.saveToStorage(this.name, this.items);
    }

    saveToStorage(name, items) {
        localStorage.setItem(name, JSON.stringify(items));
    }

    getFromStorage() {
        let items = localStorage.getItem(this.name);

        if (items) {
            items = JSON.parse(items);
        }

        if (!items) {
            items = [];
            this.saveToStorage(this.name, items);
        }
        return items;
    }

    clearItems() {
        this.saveToStorage(this.name, []);
        this.items = this.getFromStorage();
    }

}

export default new Cart();
