<template>
  <div class="cart-navigation">
    <div class="cart-navigation-header">
      <h5 class="visually-hidden">Cart</h5>
      <button
          @click="closeMenu"
          type="button"
          class="btn-close text-reset"
          aria-label="Close"
      />
    </div>
    <div class="cart-navigation-body">
      cart here
    </div>
  </div>
</template>
<script>

export default {
  name: "CartNavigation",
  components: {
  },
  data() {
    return {
      showMenu: false
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    closeMenu() {
      this.$store.commit('closeMenu')
    },
  }
}
</script>
<style lang="scss" scoped>
.cart-navigation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    margin: 0;
  }
}

.cart-navigation-body {
  padding: 0;
  overflow: visible;
}

</style>
