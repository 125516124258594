<template>
  <div class="container">
    <div class="row">
      <input type="text" v-model="dataItem" list="items" class="col-12" @input="handleInput"
             :placeholder="`Add ${dataSet}`">
      <datalist id="items">
        <option v-for="dataItem in dataItems">{{ dataItem }}</option>
      </datalist>
    </div>
  </div>
</template>
<script>

export default {
  name: "DataList",
  props: {
    dataSet: {
      type: String
    },
    dataItems: {
      type: Array,
      default: () => ([])
    },
    item: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      dataItem: ""
    }
  },
  mounted() {
    this.dataItem = this.item;
  },
  methods: {
    handleInput() {
      this.$emit("handleInput", {
        "key": this.dataSet,
        "values": this.dataItem
      });
    }
  }
}
</script>
<style scoped>
</style>
