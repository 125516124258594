function getRandomImages(images, numOfImages=20) {
    const res = [];
    for (let i = 0; i < numOfImages;) {
        // const random = Math.floor(Math.random() * images.length);
        // if (res.indexOf(images[random]) !== -1) {
        //     continue;
        // }
        res.push(images[i]);
        i++;
    }
    return res;
}

export {
    getRandomImages
};
