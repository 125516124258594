<template>
  <div>
    <div
        v-if="images && images.length"
        :class="[mixedGrid ? 'grid-mixed' : 'grid']"
    >
      <div
          @click="handleImageClick(image, index)"
          v-for="(image, index) in images"
          :key="`${image.url}-${index}`"
          class="grid-item card"
          v-b-tooltip.hover :title="image.title"
      >

        <IKImage urlEndpoint="https://ik.imagekit.io/l7yvb6qgi/" :path="getFilename(image.public_id)"
                 :transformation="transformation"/>
      </div>
    </div>
    <b-modal
        v-model="showModal"
        ref="modal"
        @hide="closeModal"
        size="xl"
        body-class="modal-main"
        hide-header
        hide-footer
    >
      <button class="close-x" @click="closeModal">
        <b-icon-x-lg/>
      </button>

      <div class="modal-actions">
        <div>
          <span
              tabindex="0"
              data-bs-toggle="tooltip"
              class="d-inline-block tooltip-wrapper disabled"
              title="You can only like once logged in!"
          >
            <base-button
                @click.native="addLike"
                variant="outline-secondary"
                :disabled="isDisabled"
            >
              <b-icon icon="hand-thumbs-up-fill"/>
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
              {{ likes }}<span class="visually-hidden">likes</span>
              </span>
            </base-button>
          </span>
        </div>
        <div>
          <base-button
              @click.native="showPurchaseModalFn"
              variant="success"
          >
            Add to Cart
          </base-button>
        </div>
      </div>

      <b-carousel
          v-if="images && images.length"
          v-model="slide"
          ref="photoCarousel"
          :interval="interval"
          :controls="controls"
      >
        <b-carousel-slide
            v-for="(item, index) in images"
            :key="`${item.url}-${index}`"
            class="slide"
            :text="images[slide].title.slice(0, 40) + '...'"
        >
          <div class="slide-img">
            <IKImage urlEndpoint="https://ik.imagekit.io/l7yvb6qgi/" :path="getFilename(item.public_id)" width="400"/>
          </div>

        </b-carousel-slide>
      </b-carousel>
      <div v-if="images && images.length" class="modal-actions responsive">
        <div>

        </div>
        <div class="button-group">
          <base-button
              @click.native="showInfoModal = !showInfoModal"
              variant="outline-secondary"
              small
          >
            <b-icon-info-circle-fill/>
            More info
          </base-button>
          <base-button
              @click.native="showShareModal = !showShareModal"
              variant="outline-secondary"
              small
          >
            <b-icon-share-fill/>
            Share
          </base-button>
        </div>
      </div>
    </b-modal>
    <b-modal
        v-if="images && images.length"
        v-model="showInfoModal"
        @hide="closeInfoModal"
        ref="modalInfo"
        body-class="modal-info"
        hide-header
        hide-footer
        centered
        no-fade
        size="sm"
    >
      <button class="close-x" @click="closeInfoModal">
        <b-icon-x-lg/>
      </button>

      <div class="modal-info-top">
        <b-row>
          <b-col cols="5" class="text-center">
            <img
                :src="images[slide].url"
                :alt="images[slide].title"
                class="modal-info-img"
            />
          </b-col>
          <b-col cols="7">
            <div class="modal-info-heading">
              <h3>Photo details</h3>
              <p>
                {{ images[slide].title }}
              </p>
            </div>
          </b-col>
        </b-row>

        <div class="modal-info-details">
          <div v-if="images[slide].collection">
            <h5>
              Collection:
            </h5>
            <p>{{ images[slide].collection }}</p>
          </div>

          <div v-if="images[slide].tags">
            <h5>
              Tags:
            </h5>
            <b-badge
                v-for="tag in images[slide].tags"
                variant="primary"
                pill
                :key="tag">
              {{ tag }}
            </b-badge>
          </div>

          <div v-if="images[slide].description">
            <h5>
              Description:
            </h5>
            <p>{{ images[slide].description }}</p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        v-if="image"
        v-model="showShareModal"
        @hide="closeShareModal"
        ref="modalShare"
        body-class="modal-share"
        hide-header
        hide-footer
        centered
        no-fade
        size="sm"
    >
      <button class="close-x" @click="closeShareModal">
        <b-icon-x-lg/>
      </button>
      <h3>Spread the word</h3>
      <p>Share this image on your social media account</p>

      <social-sharing
          :url="`${getImageUrl(images[slide])}`"
          title="Rare and authentic photography at Thomeimages!"
      />
    </b-modal>
    <b-modal
        v-if="images && images.length"
        v-model="showPurchaseModal"
        @hide="closePurchaseModal"
        ref="modalPurchase"
        body-class="modal-purchase"
        hide-header
        hide-footer
        centered
        no-fade
    >
      <button class="close-x" @click="closePurchaseModal">
        <b-icon-x-lg/>
      </button>

      <div class="modal-purchase-top">

        <div class="product-info">
          <div class="product-info-wrapper rounded">
            <div class="product-heading">
              <h3>Purchase License</h3>
              <p class="text-sm">
                Here is a list of available size options for purchase for this image.
              </p>
            </div>
            <form>
              <product-license
                  v-for="(license, index) in licenses"
                  :key="index"
                  :license="license"
                  @setCartItem="setCartItem"
              />
            </form>

            <div class="product-add-to-cart">
              <h5>${{ cartItem.license.price }}</h5>
              <div class="d-flex flex-row justify-content-between">
                <div class="btn btn-md btn-primary" @click="addCart">Add to cart</div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import ImageService from "@/services/image/ImageService";
import BaseButton from "@/components/base/BaseButton";
import ProductLicense from "@/components/ProductLicense.vue";
import Cart from "@/assets/cart.js";
import SocialSharing from "@/components/SocialSharing.vue";
import {IKImage} from "imagekitio-vue";

export default {
  name: 'Gallery',
  components: {
    SocialSharing,
    BaseButton,
    ProductLicense,
    IKImage
  },
  props: {
    images: {
      type: Array,
      default: [],
      required: true,
    },
    mixedGrid: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      transformation: [
        {
          "crop": "center",
          "quality": 70,
          "width": "600",
          "height": "600"
        }
      ],
      ImageService: ImageService,
      showModal: false,
      showInfoModal: false,
      showPurchaseModal: false,
      showShareModal: false,
      slide: 0,
      sliding: null,
      interval: 3000,
      controls: !window.matchMedia('only screen and (max-width: 768px)').matches,
      currentImage: {},
      currentIndex: '',
      originalPath: '',

      index: 0,
      imageId: null,
      image: {
        title: '',
        description: '',
        secure_url: '',
        likes: []
      },
      hasSharing: false,
      shareUrl: null,
      renderKey: {
        sharing: 0,
        related: 0
      },
      licenses: [],
      cartItem: {
        "image": {
          "id": "",
          "url": ""
        },
        "license": {
          "name": "",
          "price": 0
        }
      }
    }
  },

  computed: {
    likes() {
      if (this.image.likes) {
        return this.image.likes.length;
      }
      return 0;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    loggedInUser() {
      return this.$store.state.user;
    },
    isDisabled() {
      return !this.$store.state.isLoggedIn;
    }
  },
  watch: {
    slide: async function (val) {
      let image = this.images[val];
      await this.setImageLicenses(image);
      this.imageId = image.asset_id;
      window.history.pushState("", `Photo - ${image.title}`, `/photo/${image.asset_id}`);
    }

  },
  methods: {
    getFilename(filePath) {
      return (filePath !== undefined ? filePath.split('/').pop() : "");
    },
    getImageUrl(image) {
      if (image === undefined) {
        return window.location.href;
      }
      return `${window.location.host}/photo/${image.asset_id}`;
    },
    async showPurchaseModalFn() {
      let image = this.images[this.slide];
      this.imageId = image.asset_id;
      await this.setImageLicenses(image);
      this.showPurchaseModal = true;
    },
    async setImageLicenses(image) {
      let plan = this.getImagePlan(image);
      this.licenses = await this.getPlanLicenses(plan);
    },
    formatDate(iso) {
      const date = new Date(iso);
      const month = date.toLocaleString('default', {month: 'long'});
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month} ${day}, ${year}`;
    },
    getGridClass() {
      const items = ['type-0', 'type-1', 'type-2', 'type-3']
      return items[Math.floor(Math.random() * items.length)]
    },
    handleImageClick(image, idx) {
      this.currentImage = image;
      this.currentIndex = idx;
      this.slide = idx;
      this.showModal = true;
      this.originalPath = this.$route.path;
      window.history.pushState("", `Photo - ${image.title}`, `/photo/${image.asset_id}`);
      this.$emit('image-clicked', image);
    },
    closeModal() {
      this.showModal = false;
      history.pushState(
          {},
          null,
          this.originalPath
      )
    },
    closeInfoModal() {
      this.showInfoModal = false;
    },
    closePurchaseModal() {
      this.showPurchaseModal = false;
    },
    closeShareModal() {
      this.showShareModal = false;
    },
    async getImage(imageId) {
      return ImageService.getImage(imageId);
    },
    async setImageTags() {
      const stringTagsPresent = "tags" in this.image && this.image.tags.length > 0 && typeof (this.image.tags) === "string";
      this.image.tags = (stringTagsPresent ? this.image.tags.split(",") : this.image.tags);
    },

    getImagePlan: function (image) {
      return (image.access_mode === "public" || image.access_mode ? "free" : "paid");
    },
    async getPlanLicenses(plan) {
      const secretKey = `${process.env.VUE_APP_STRIPE_SECRET_KEY}`;
      const stripeApiUrl = "https://api.stripe.com/v1";
      const productsUrl = `${stripeApiUrl}/products/search?query=metadata["app"]:"picha"&expand[]=data.default_price`;

      const products = await this.getResourceData(productsUrl, secretKey, "data");
      const licenses = await Promise.all(products.map(async (product) => {
        const productName = product.name;
        const price = product.default_price.unit_amount;
        return {
          "id": product.default_price.id,
          "name": productName,
          "description": product.description,
          "price": parseFloat((price / 100).toString()).toFixed(2),
          "plan": (productName.toLowerCase().includes("free") ? "free" : "paid"),
          "width": product.metadata.width,
          "height": product.metadata.height
        };
      }));
      return licenses.filter((license) => license.plan === plan);
    },
    setCartItem(licenseItem) {
      let image = this.images[this.slide]
      this.cartItem.image = {
        'id': this.imageId,
        'public_id': image.public_id,
        'details': image,
        'url': ImageService.getImageKitUrl(image.public_id, {width: '50', height: '50'}, true),
        'downloadUrl': ImageService.getImageKitUrl(image.public_id, {
          width: licenseItem.width,
          height: licenseItem.height
        }),
      };
      this.cartItem.license = licenseItem;
    },
    updateCartCount: function (totalCartItems) {
      this.$emit('updateCartCount', totalCartItems);
    },
    async getResourceData(resourceUrl, token, responseIndex) {
      return fetch(resourceUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }).then(res => res.json()).then(response => {
        return response[responseIndex];
      });
    },

    addCart() {
      const isSelected = this.cartItem.license.name !== "";
      if (isSelected) {
        let itemSelected = this.cartItem;
        let itemMatch = Cart.getItem(itemSelected);
        if (itemMatch) {
          this.$toast.error('Item already in Cart!');
        }
        if (!itemMatch) {
          Cart.addItem(itemSelected);
          this.$toast.success('Item added to Cart!');
          this.$emit('updateCartCount', Cart.countItems());

          window.pintrk('track', 'addtocart', {
            value: itemSelected.license.price,           // The value of the product added to the cart
            order_quantity: 1,      // The quantity of the product added to the cart
            currency: 'USD',        // The currency of the transaction
            product_id: itemSelected.image.id     // The ID of the product added to the cart
          });
        }
      }
    },


    getImageLikes(image) {
      if (["number"].includes(typeof image.likes)) {
        return [];
      }
      return image.likes;
    },
    async getShareUrl(image) {
      //TODO: This needs to grab the current url path
      return `${window.location.origin}/photo/${image.asset_id}`;
    },

    async addLike() {
      const userEmail = this.loggedInUser.email;
      if (userEmail === undefined) {
        this.$toast.error('You must be logged in to like an image!');
      } else {
        const image = this.images[this.slide];
        const imageId = image.asset_id;
        let imageLikes = image.likes;
        if (imageLikes === undefined) {
          image.likes = [];
        }
        if (typeof imageLikes === "number") {
          image.likes = [];
          imageLikes = image.likes;
        }
        const imageLiked = imageLikes.includes(userEmail);
        if (imageLiked) {
          this.$toast.success('You already liked this image!');
        } else {
          image.likes.push(userEmail);
          await ImageService.updateImage(imageId, image);
          this.image = await this.getImage(imageId);
          this.$toast.success('Image liked!');
        }
      }
    }
  },
  mounted() {
    this.interval = 0;
  }
}
</script>

<style lang="scss" scoped>
$timing: 300ms;

::v-deep {
  .carousel-caption {
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px;
    color: inherit;
    text-align: left;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 2rem;
    top: 300px;
  }

  .carousel-control-prev {
    left: -140px;
  }

  .carousel-control-next {
    right: -140px;
  }
}

.slide {
  .slide-img {
    text-align: center;

    img {
      margin: 0 auto;
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
        height: 650px;
      }
    }
  }
}

.placeholder-img {
  height: 100%;
  cursor: pointer;
  background-position: 50% 50%;
  border-radius: 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(180px, auto);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(240px, auto);
  }
}

.grid-mixed {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 300px 300px;

    .grid-item {
      background: #fec70a;

      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 300px 300px 300px 300px;

    .grid-item {
      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }

  }
}

.card {
  min-height: 100px;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
  border: 0;
  transition: all $timing ease-in-out;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    opacity: 0;
    color: #fff;
    font-family: 'Bootstrap-icons';
    font-size: 26px;
    content: '\F52A';
    pointer-events: none;
    z-index: 9000;
    transition: all $timing ease-in-out;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(46, 132, 206, 0.7);
    content: '';
    cursor: pointer;
    pointer-events: none;
    transition: all $timing ease-in-out;
  }

  &:hover {
    transform: scale(1.025);

    &::before {
      animation: fly-in $timing forwards;
    }

    &::after {
      opacity: 1;
    }
  }
}

@keyframes fly-in {
  from {
    opacity: 0;
    left: 100%;
    transform: scale(5) rotate(-360deg);
  }
  to {
    opacity: 1;
    left: 50%;
    transform: scale(1) rotate(0);
  }
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}

.btn {
  position: relative;
}

</style>