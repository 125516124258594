<template>
  <div>
    <div class="masthead">
      <div class="container-xl">
        <search-bar place-holder="Search for images" @handleSearch="handleSearch"/>
      </div>
    </div>
    <div class="page container-xl">
      <div>
        <gallery-admin :prop-images="paginatedItems"  v-if="paginatedItems.length>0"/>


      </div>
      <div>
        <pagination :number-of-items="computedItems.length" :items-per-page="itemEndIndex"
                    @handlePagination="handlePagination"/>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import SearchBar from "@/components/SearchBar";
import ImageService from "@/services/image/ImageService";
import GalleryAdmin from "@/components/GalleryAdmin.vue";

export default {
  name: 'ImagesAdmin',
  components: {
    GalleryAdmin,
    Pagination,
    SearchBar,
  },
  data() {
    return {
      searchQuery: null,
      itemStartIndex: 0,
      itemEndIndex: 0,
      items: [],
      computedItems: [],
      paginatedItems: [],
      loadImagesKey: 0
    }
  },
  watch: {
    searchQuery: {
      async handler(newSearchQuery) {
        this.itemStartIndex = 0;
        this.itemEndIndex = 96;
        this.items = await this.getItems();
        this.computedItems = this.getComputedItems(newSearchQuery);
        this.paginatedItems = this.getPaginatedItems(this.itemStartIndex, this.itemEndIndex);
      },
      immediate: true
    }
  },
  methods: {
    getComputedItems(searchQuery) {
      if (searchQuery && searchQuery.length >= 3) {
        return this.items.filter((item) => {
          return searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => "tags" in item && item.tags.map(tag => tag.toLowerCase()).includes(v));
        });
      } else {
        return this.items;
      }
    },
    getPaginatedItems(itemStartIndex, itemEndIndex) {
      return this.computedItems.slice(itemStartIndex, itemEndIndex);
    },
    handlePagination(pagination) {
      this.itemStartIndex = pagination.itemStartIndex;
      this.itemEndIndex = pagination.itemEndIndex;
      this.paginatedItems = this.getPaginatedItems(this.itemStartIndex, this.itemEndIndex);
    },
    handleSearch(searchQuery) {
      this.searchQuery = searchQuery;
    },
    async getItems() {
      let images = await ImageService.getImages();
      console.log(images);
      return images;
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
