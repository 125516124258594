<template>
  <slip>
    <div class="order-item-line">
      <label>Order ID:</label>
      <span class="truncate"> {{ orderHash }}</span>
    </div>
    <div class="order-item-line col-lg-6">
      <label>Number of items:</label>
      <span> {{ orderItemNumber }}</span>
    </div>
    <div class="order-item-line total col-lg-6">
      <label>Order Amount:</label>
      <span> ${{ orderAmount }}</span>
    </div>
    <div class="order-item-line actions">
      <div class="btn btn-sm btn-secondary" @click="viewItems(orderId)">View Items</div>
    </div>
  </slip>
</template>

<script>
import {generateHash} from "@/utils/HashUtil";
import Slip from "@/components/Slip";

export default {
  name: "OrderItem",
  components: {Slip},
  props: {
    orderId: {
      type: String
    },
    orderItemNumber: {
      type: Number
    },
    orderAmount: {
      type: Number
    },
  },
  computed:{
    orderHash(){
      return generateHash(this.orderId)
    }
  },
  methods: {
    viewItems(orderId) {
      this.$router.push({name: "Download", params: {cartId: orderId}});
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-line {
  margin: 1rem 0 0;

  &:first-child {
    margin-top: 0;
  }

  label,
  span {
    display: block;
  }

  label {
    width: auto;
    font-size: 0.75rem;
    color: #777777;
    // border-bottom: 5px solid #e2e2e2;
    margin: 0 0 0.5rem;
  }

  span {
    font-weight: 500;
    padding: .5rem;
    background: rgba(0,0,0,.05);
  }
}

.actions {
  margin: 1.5rem 0 0;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
