<template>
  <div class="base-card">
    <b-card class="card" v-bind="$attrs">
      <slot />
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
}
</script>

<style lang="scss" scoped>
$text-color: #324a66;

.base-card {
  .card {
    position: relative;
    padding: 2rem 0;
    border: 0;
    border-radius: 0;
    box-shadow: 0 3px 0 0 rgba(65, 62, 102, 0.08);
    transition: all 300ms ease-in-out;

    @media (max-width: 991px) {
      margin-bottom: 50px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 7px;
      background-color: #40a4d7;
      transition: 0.5s;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.08);

      &::after {
        width: 100%;
      }
    }
  }

  .card-header {
    background-color: white;
    border-bottom: 0;
    text-align-last: center;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 0;
    color: lighten(#324a66, 10%);
  }

  .card-title {
    margin-bottom: 1rem;
    color: $text-color;
  }

  .card-block {
    padding-top: 0;
    text-align: center;
  }

  .card-body {
    margin-top: 0.0001rem;
    margin-bottom: 0.1rem;
    padding: 0.01rem;
  }

  .btn {
    display: inline-block;
    width: auto;
    padding: .5rem 1.5rem;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

}

</style>