<template>
  <div class="pt-1">
    <ShareNetwork
        class="btn btn-sm"
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :style="{ backgroundColor: network.color }"
        :url="url"
        :title="title"
        :description="description"
        :quote="quote"
        :hashtags="hashtags"
    >
      <span class="text-white">
        <em :class="network.icon"></em> <strong>{{ network.name }}</strong>
      </span>
    </ShareNetwork>
  </div>
</template>
<script>
export default {
  name: "SocialSharing",
  props: {
    url: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    quote: {
      type: String,
      default: ""
    },
    hashtags: {
      type: String,
      default: ""
    },
    showName: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sharing: this.shareContent,
      networks: [
        {
          network: "facebook",
          name: (this.showName ? "Facebook" : ""),
          icon: "bi bi-facebook",
          color: "#1877f2",
        },
        {
          network: "pinterest",
          name: (this.showName ? "Pinterest" : ""),
          icon: "bi bi-pinterest",
          color: "#bd081c",
        },
        {
          network: "twitter",
          name: (this.showName ? "Twitter" : ""),
          icon: "bi bi-twitter",
          color: "#1da1f2",
        },
        {
          network: "whatsapp",
          name: (this.showName ? "Whatsapp" : ""),
          icon: "bi bi-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin: 0 .25rem 0 0;

  &:last-child {
    margin: 0;
  }
}
</style>
