/**
 * ImageModel is the image model class.
 * @class
 * @constructor
 * @public
 * @property {string} asset_id - image asset_id
 * @property {string} title - image title
 * @property {string} description - image description
 * @property {string} public_id - image public_id
 * @property {string} url - image url
 * @property {array} tags - image tags
 * @property {string} format - image format
 * @property {array} likes - image likes
 * @property {boolean} access_mode - image access_mode
 * @property {string} creator - image creator name
 * @property {Date} createdAt - image createdAt
 */

class ImageModel {
    constructor(asset_id, title, public_id, url, format, creator, collection) {
        this.asset_id = asset_id;
        this.title = title;
        this.description = "";
        this.public_id = public_id;
        this.url = url;
        this.secure_url = url;
        this.tags = [];
        this.format = format;
        this.likes = [];
        this.access_mode = false;
        this.creator = creator;
        this.collection = collection;
        this.created_at = new Date();
    }
}

export default ImageModel;
