<template>
  <div class="images">
    <page-header
        title="Images"
        description="Find images by browsing or searching by keyword."
        no-margin
        theme="dark"
    >
      <search-bar
          @handleSearch="handleSearch"
          place-holder="Search for images"
      />
    </page-header>

    <div class="page container-xl">
      <div v-if="paginatedItems.length<=0">
        <b-jumbotron>
          <h1 class="display-3">No images found</h1>
          <p class="lead">Try searching for something else.</p>
        </b-jumbotron>
      </div>
      <base-loader v-if="loading" large block />
      <h4 v-if="!loading && paginatedItems.length>0">
        Showing {{ computedItems.length }} results for <span>{{ searchQuery }}</span>
      </h4>
      <gallery
        v-if="!loading && paginatedItems.length>0"
        :images="paginatedItems"
        @updateCartCount="updateCartCount"
      />
      <div v-if="paginatedItems.length>0">
        <pagination
            :number-of-items="computedItems.length"
            :items-per-page="itemEndIndex"
            :start-page="startPage"
            @handlePagination="handlePagination"
            :key="startPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import {getImagesFromLocalStorage, saveImagesToLocalStorage} from "@/assets/common";
import Pagination from "@/components/Pagination";
import SearchBar from "@/components/SearchBar";
// import ImageService from "@/services/image/ImageService";
import PageHeader from "@/components/PageHeader";
import Gallery from "@/components/Gallery";
import BaseLoader from "@/components/base/BaseLoader";

export default {
  name: 'Images',
  components: {
    BaseLoader,
    Gallery,
    PageHeader,
    Pagination,
    SearchBar
  },
  data() {
    return {
      loading: null,
      searchQuery: '',
      itemStartIndex: 0,
      itemEndIndex: 0,
      items: [],
      computedItems: [],
      paginatedItems: [],
      loadImagesKey: 0,
      startPage: 1,
      convertedTags: []
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.searchQuery = this.$route.query.search;
    this.loading = true;
  },
  watch: {
    searchQuery: {
      async handler(newSearchQuery) {
        this.loading = true;
        this.itemStartIndex = 0;
        this.itemEndIndex = 96;
        this.items = await this.getItems();
        this.computedItems = this.getComputedItems(newSearchQuery);
        this.paginatedItems = this.getPaginatedItems(this.itemStartIndex, this.itemEndIndex);
        this.loading = false;
      },
      immediate: true
    }
  },
  methods: {
    async getItems() {
      // console.log(await this.$store.dispatch("images/getImages"))
      return await this.$store.dispatch("images/getImages")
    },
    updateCartCount(cartItems) {
      this.$emit("updateCartCount", cartItems)
    },
    getComputedItems(searchQuery) {
      if (searchQuery && searchQuery.length >= 3) {
        return this.items.filter((item) => {
          let found = searchQuery
              .trim()
              .toLowerCase()
              .split(" ")
              .some((v) => "tags" in item && item.tags.map(tag => {
                return tag.trim().toLowerCase().split(" ").join("-");
              }).map(item => item.match(v)).some(obj => Array.isArray(obj)));
          return found
        });
      } else {
        return this.items;
      }
    },
    getPaginatedItems(itemStartIndex, itemEndIndex) {
      return this.computedItems.slice(itemStartIndex, itemEndIndex);
    },
    handlePagination(pagination) {
      this.itemStartIndex = pagination.itemStartIndex;
      this.itemEndIndex = pagination.itemEndIndex;
      this.paginatedItems = this.getPaginatedItems(this.itemStartIndex, this.itemEndIndex);
    },
    handleSearch(searchQuery) {
      const formattedQuery = searchQuery ? searchQuery.toLowerCase() : null;

      if (searchQuery !== this.searchQuery) {
        this.$router.replace({
          query: {
            search: formattedQuery,
          }
        })
      }
      this.searchQuery = formattedQuery;
    }
  }
}
</script>
<style lang="scss" scoped>
.images {
  background: #ffffff;
}

h4 {
  display: block;
  margin-top: 20px;
  padding: 0;
  padding-right: unset;
  padding-left: unset;
  border: none;
  outline: none;
  background: 0 0;
  color: #777777;
  fill: #777777;
  font-weight: 400;
  font-size: 23px;
  line-height: 36px;
  letter-spacing: -0.015em;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    color: #083260;
    font-weight: 600;
    text-transform: capitalize;
  }
}
</style>
