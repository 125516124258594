class EmailService {
    constructor() {
        this.sendGridkey = process.env.VUE_APP_SENDGRID_API_KEY;
        this.emailServiceEndpoint = process.env.VUE_APP_EMAIL_SERVICE_ENDPOINT;
        this.emailSenderName = process.env.VUE_APP_EMAIL_SERVICE_SENDER_NAME;
        this.emailSenderEmail = process.env.VUE_APP_EMAIL_SERVICE_SENDER_EMAIL;
    }

    sendMail(email, message) {
        let myHeaders = new Headers();
        myHeaders.append("sendgridApiKey", `${this.sendGridkey}`);
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "sender_email": `${this.emailSenderEmail}`,
            "sender_name": `${this.emailSenderName}`,
            "recipient_email": `${email}`,
            "message": `${message}`
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${this.emailServiceEndpoint}`, requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error', error));
    }

}

export default new EmailService();
