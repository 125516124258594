<template>
  <div>
  </div>
</template>

<script>
import AccountsService from "@/services/accounts/AccountsService";

export default {
  name: "VerifyAccount",
  mounted() {
    this.verifyAccount()
  },
  methods: {
    verifyAccount() {
      let id = this.$route.query.token
      if (id) {
        AccountsService.updateUserAccountDetails(id, {verified: true})
        this.$toast.success("Your account has been verified. You can now sign in.")
        this.$router.push({name: "Home"})
      }
      this.$store.commit("setActiveMenuView", 'signin')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
