<template>
  <div>
    <button :class="cartButton.class" type="button" @click="cartButton.clickEvent()">
      {{ cartButton.text }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CartButton",
  props: {
    cartButton: {
      type: Object,
      default() {
        return {
          class: "",
          text: "",
          clickEvent: function () {

          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>