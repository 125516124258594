<template>
  <div class="page container-xl">
    <div class="alert alert-danger">
      <h1>THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.
      </h1>
      <div>
        YOU MAY HAVE MISTYPED THE ADDRESS OR THE PAGE MAY HAVE MOVED.
      </div>
    </div>
  </div>
</template>

<script>
import MainNavigation from "../components/MainNavigation";
import MainFooter from "../components/MainFooter";

export default {
  name: "PageNotFound",
  components: {MainFooter, MainNavigation}
}
</script>

<style scoped>

</style>